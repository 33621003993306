import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { createItem, deleteItem, readItems, updateItem } from '@directus/sdk';

// Define the Leasing data structure
export interface LeasingData {
    id: number;
    // tenant_name: string;
    property_id: {
      id: number;
      property_name: string;
      // Add other fields from the properties collection if needed
    };
    tenant_id: {
        id: number;
        full_name: string;
        // Add other fields from the properties collection if needed
      };
    lease_start_date: string;
    lease_end_date: string;
    monthly_rent: number;
    status: string;
    team_id?: number;
    date_created?: string;
    date_updated?: string;
    user_created?: string;
    user_updated?: string;
  }

// Thunks for async actions
export const fetchLeasings = createAsyncThunk('leasings/fetchLeasings', async () => {
    const response = await directus.request<LeasingData[]>(readItems('leases', {
        fields: ['*', 'property_id.id','property_id.property_name', 'tenant_id.id', 'tenant_id.full_name'] // Include all leasing fields and property data
    }));
    return response;
});

export const addLeasing = createAsyncThunk('leasings/addLeasing', async (newLeasing: LeasingData) => {
    const { id, ...leasingDataWithoutId } = newLeasing;
    const response = await directus.request<LeasingData>(createItem('leases', leasingDataWithoutId));
    return {
        ...newLeasing,
        id: response.id,
      };
});

export const updateLeasing = createAsyncThunk('leasings/editLeasing', async (updatedLeasing: LeasingData) => {
  const response =  await directus.request<LeasingData>(updateItem('leases', updatedLeasing.id, updatedLeasing));
//   return response;
  return {
    ...response,
    property_id: updatedLeasing.property_id,
    tenant_id: updatedLeasing.tenant_id,
    // tenant_name: updatedLeasing.tenant_id?.full_name
  };
});

export const deleteLeasing = createAsyncThunk('leasings/deleteLeasing', async (id: number) => {
  await directus.request<LeasingData>(deleteItem('leases', id));
  return id;
});

// Slice for managing leasing state
const leasingsSlice = createSlice({
  name: 'leasings',
  initialState: {
    data: [] as LeasingData[],
    status: 'idle',
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeasings.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchLeasings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchLeasings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch leasings';
      })
      .addCase(addLeasing.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(addLeasing.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to create leasings';
      })
      .addCase(updateLeasing.fulfilled, (state, action) => {
        const index = state.data.findIndex((leasing) => leasing.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
      })
      .addCase(deleteLeasing.fulfilled, (state, action) => {
        state.data = state.data.filter((leasing) => leasing.id !== action.payload);
      });
  },
});

export default leasingsSlice.reducer;
