import { configureStore } from '@reduxjs/toolkit';
import propertiesReducer from './propertiesSlice';
import leasingsReducer from './leasingsSlice';
import tenantsReducer from './tenantsSlice'; 
import expensesReducer from './expensesSlice'; 
import userReducer from './userSlice';
import filesReducer from './filesSlice';
import reportsReducer from './reportsSlice';
export const store = configureStore({
  reducer: {
    properties: propertiesReducer,
    leasings: leasingsReducer, // Add leasings reducer to the store
    tenants: tenantsReducer, // Add leasings reducer to the store
    expenses: expensesReducer, // Add leasings reducer to the store
    user: userReducer, // Add leasings reducer to the store
    files: filesReducer,
    reports: reportsReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
