import React, { useEffect, useState } from 'react';
import { Card, List, Spin, Tag, Typography, Button, Modal } from 'antd';
import { ExclamationCircleOutlined, ClockCircleOutlined, InfoCircleOutlined, StarOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import directus from '../utils/directusClient';
import { triggerFlow } from '@directus/sdk';

const { Text } = Typography;

// Use the same flow trigger ID as SearchBox
const flow_trigger_id = process.env.REACT_APP_ENV === 'prod' ?
  "96b36378-a628-4f0c-921d-3d804c5d7b88" : "a7ec107a-4164-472e-b18f-e2fe7a2af232";

// Add this constant at the top with other constants
const SUGGESTIONS_VALIDITY_HOURS = 4;

interface Suggestion {
  priority: 'high' | 'medium' | 'low';
  content: string;
}

const AISuggestions: React.FC = () => {
  const [loading, setLoading] = useState(false);
  // Initialize suggestions from localStorage if available
  const [suggestions, setSuggestions] = useState<Suggestion[]>(() => {
    const saved = localStorage.getItem('aiSuggestions');
    return saved ? JSON.parse(saved) : [];
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataReady, setDataReady] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Get the same data as SearchBox
  const properties = useSelector((state: RootState) => state.properties.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const expenses = useSelector((state: RootState) => state.expenses.data);
  const tenants = useSelector((state: RootState) => state.tenants.data);


  const propertiesStatus = useSelector((state: RootState) => state.properties.status);
  const leasingsStatus = useSelector((state: RootState) => state.leasings.status);
  const expensesStatus = useSelector((state: RootState) => state.expenses.status);
  const tenantsStatus = useSelector((state: RootState) => state.tenants.status);

  const getPriorityIcon = (priority: string) => {
    switch (priority) {
      case 'high':
        return <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />;
      case 'medium':
        return <ClockCircleOutlined style={{ color: '#faad14' }} />;
      case 'low':
        return <InfoCircleOutlined style={{ color: '#52c41a' }} />;
      default:
        return null;
    }
  };

  const isSuggestionsValid = () => {
    const lastFetch = localStorage.getItem('aiSuggestionsTimestamp');
    if (!lastFetch) return false;

    const lastFetchDate = new Date(lastFetch);
    const now = new Date();
    const hoursSinceLastFetch = (now.getTime() - lastFetchDate.getTime()) / (1000 * 60 * 60);
    
    return hoursSinceLastFetch < SUGGESTIONS_VALIDITY_HOURS;
  };

  const fetchSuggestions = async () => {
    // Replace the simple timestamp check with the validity check
    if (isSuggestionsValid()) {
      return;
    }

    setLoading(true);
    const prompt = `
      You are a property management assistant. Based on the following data, provide exactly 3 important suggestions or tasks that need attention today.\\n
      Return ONLY a JSON array with objects containing priority (high/medium/low) and content fields.\\n
      Consider urgent matters, upcoming deadlines, and potential issues.\\n
      Properties: ${JSON.stringify(properties)}\\n
      Leasings: ${JSON.stringify(leasings)}\\n
      Tenants: ${JSON.stringify(tenants)}\\n
      Expenses: ${JSON.stringify(expenses)}\\n
    `;

    try {
      const response = await directus.request(
        triggerFlow('POST', flow_trigger_id, {
         "prompt": JSON.stringify(prompt)
        })
      ) as any;

      let content = response.choices[0].message.content;
      content = content.replace(/```json\n?/g, '').replace(/```/g, '').trim();
      
      const newSuggestions = JSON.parse(content);
      setSuggestions(newSuggestions);
      // Store suggestions and timestamp in localStorage
      localStorage.setItem('aiSuggestions', JSON.stringify(newSuggestions));
      localStorage.setItem('aiSuggestionsTimestamp', new Date().toISOString());
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if all required data is available
    const allSucceeded = 
    propertiesStatus === 'succeeded' &&
    leasingsStatus === 'succeeded' &&
    expensesStatus === 'succeeded' &&
    tenantsStatus === 'succeeded';

    const hasData = 
    properties.length > 0 ||
    leasings.length > 0 ||
    expenses.length > 0 ||
    tenants.length > 0;

    if (allSucceeded && hasData) {
      setDataReady(true);
    }
    }, [properties, leasings, expenses, tenants, dataReady]);

  useEffect(() => {
    if (dataReady) {
      fetchSuggestions();
    }
  }, [dataReady]);

  useEffect(() => {
    if (suggestions.length === 0) return;
    
    const interval = setInterval(() => {
      setCurrentIndex((current) => 
        current === suggestions.length - 1 ? 0 : current + 1
      );
    }, 15000); // 15 seconds

    return () => clearInterval(interval);
  }, [suggestions]);

  // Add this component for reusable suggestion list
  const SuggestionsList: React.FC<{ suggestions: Suggestion[], itemStyle?: React.CSSProperties }> = 
    ({ suggestions, itemStyle }) => (
    <List
      itemLayout="horizontal"
      dataSource={suggestions}
      renderItem={(item) => (
        <List.Item style={itemStyle}>
          <List.Item.Meta
            avatar={getPriorityIcon(item.priority)}
            title={
              <Text style={{ fontWeight: 'normal' }}>
                {item.content}
                <Tag color={
                  item.priority === 'high' ? 'red' :
                  item.priority === 'medium' ? 'orange' : 'green'
                } style={{ marginLeft: 8 }}>
                  {item.priority}
                </Tag>
              </Text>
            }
          />
        </List.Item>
      )}
    />
  );

  return (
    <>
      <Card 
        title={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            AI Suggestions
            <span style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
              <StarOutlined style={{ color: 'gold', fontSize: '12px' }} />
              <span style={{ color: 'gold', fontSize: '12px', marginLeft: '0px' }}>Pro</span>
            </span>
          </span>
        } 
        extra={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {loading && <Spin size="small" />}
            {/* <Button size="small" onClick={() => setIsModalVisible(true)}> */}
            <Button type="link" onClick={() => setIsModalVisible(true) } style={{ padding: 0, marginRight: '-5px', color: '#1C4E80' }}>
              More
            </Button>
          </div>
        }
      >
        <div style={{ height: '150px', overflow: 'auto' }}>
          {suggestions.length > 0 && (
            <SuggestionsList 
              suggestions={[suggestions[currentIndex]]} 
              itemStyle={{ padding: '4px 0' }}
            />
          )}
        </div>
      </Card>

      <Modal
        title="All AI Suggestions"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
      >
        <SuggestionsList suggestions={suggestions} />
      </Modal>
    </>
  );
};

export default AISuggestions; 