import React, { useEffect } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { HomeOutlined, UserOutlined, ExceptionOutlined, DollarOutlined, FileDoneOutlined, ToolOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../redux/store';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { expensesFormatter, revenueFormatter } from '../utils/currencyFormatter';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';
import dayjs from 'dayjs'; // Import dayjs for date manipulation

const DashboardTopBar: React.FC = () => {
  const properties = useSelector((state: RootState) => state.properties.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const tenants = useSelector((state: RootState) => state.tenants.data);
  const expenses = useSelector((state: RootState) => state.expenses.data);
  // const status = useSelector((state: RootState) => state.properties.status);

  // const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (status === 'idle') {
  //     dispatch(fetchProperties());
  //     dispatch(fetchLeasings());
  //     dispatch(fetchTenants());
  //     dispatch(fetchExpenses());
  //   }
  // }, [status, dispatch]);

  // Calculate the total monthly revenue
const filterRevenueByMonth = (month: number, year: number) => {
  return leasings
    .filter(lease => {
      const leaseStartDate = dayjs(lease.lease_start_date);
      const leaseEndDate = lease.lease_end_date ? dayjs(lease.lease_end_date) : null;

      // Define the start and end dates of the current month
      const startOfMonth = dayjs().month(month).year(year).startOf('month');
      const endOfMonth = dayjs().month(month).year(year).endOf('month');

      // Check if the lease is active during the current month
      return lease.status === 'signed' &&
        (leaseEndDate ? leaseStartDate.isBefore(endOfMonth) && leaseEndDate.isAfter(startOfMonth) : leaseStartDate.isBefore(endOfMonth));
    })
    .reduce((sum, lease) => sum + (lease.monthly_rent || 0), 0);
};

  const now = dayjs();
  const currentMonth = now.month();
  const currentYear = now.year();

  // Current month's total revenue
  const totalCurrentMonthRevenue = filterRevenueByMonth(currentMonth, currentYear);

  // Last month's total revenue
  const lastMonthDate = now.subtract(1, 'month');
  const lastMonth = lastMonthDate.month();
  const lastYear = lastMonthDate.year();
  const totalLastMonthRevenue = filterRevenueByMonth(lastMonth, lastYear);

  // Calculate percentage change in revenue
  const revenueDifference = totalCurrentMonthRevenue - totalLastMonthRevenue;
  const revenuePercentageChange = totalLastMonthRevenue === 0
    ? (totalCurrentMonthRevenue > 0 ? '100' : '0')
    : ((revenueDifference / totalLastMonthRevenue) * 100).toFixed(2);

  // Calculate the total monthly expenses
  const filterExpensesByMonth = (month: number, year: number) => {
    return expenses.filter(expense => {
      const expenseDate = dayjs(expense.date);
      return expenseDate.month() === month && expenseDate.year() === year;
    });
  };

  // Get current month's expenses
  const currentMonthExpenses = filterExpensesByMonth(currentMonth, currentYear);
  const totalCurrentMonthExpenses = currentMonthExpenses.reduce((sum, expense) => sum + Number(expense.amount || 0), 0);

  // Get last month's expenses
  const lastMonthExpenses = filterExpensesByMonth(lastMonth, lastYear);
  const totalLastMonthExpenses = lastMonthExpenses.reduce((sum, expense) => sum + Number(expense.amount || 0), 0);

  // Calculate percentage change in expenses
  const expenseDifference = totalCurrentMonthExpenses - totalLastMonthExpenses;
  const expensePercentageChange = totalLastMonthExpenses === 0
    ? (totalCurrentMonthExpenses > 0 ? '100' : '0')
    : ((expenseDifference / totalLastMonthExpenses) * 100).toFixed(2);

  // Handlers for navigation
  const handlePropertiesClick = () => navigate('/properties');
  const handleLeasingsClick = () => navigate('/leasings');
  const handleExpensesClick = () => navigate('/expenses');
  const handleTenantsClick = () => navigate('/tenants');

  return (
    <div style={{ padding: '20px', background: '#fff' }}>
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Total Properties"
              prefix={<HomeOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handlePropertiesClick}
                >
                  {properties.length}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Active Leases"
              prefix={<FileDoneOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleLeasingsClick}
                >
                  {leasings.filter(lease => lease.status == 'signed').length}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Monthly Revenue"
              value={totalCurrentMonthRevenue}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              formatter={revenueFormatter}
              valueRender={() => (
                <span>
                  {revenueFormatter(totalCurrentMonthRevenue)}
                  { (
                    <span style={{ fontSize: 'medium', marginLeft: 8, color: revenueDifference >= 0 ? '#4caf50' : '#f44336' }}>
                      {revenueDifference >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                      {Math.abs(Number(revenuePercentageChange))}%
                    </span>
                  )}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Total Tenants"
              prefix={<UserOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleTenantsClick}
                >
                  {tenants.length}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          {/* <Card bordered={false}>
            <Statistic
              title="Pending Requests"
              value={5}
              prefix={<ToolOutlined />}
              valueStyle={{ color: '#1C4E80' }}
            />
          </Card> */}
          <Card bordered={false}>
            <Statistic
              title="Pending Leases"
              prefix={<ExceptionOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleLeasingsClick}
                >
                  {leasings.filter(lease => lease.status != 'signed').length}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="Monthly Expenses"
              value={totalCurrentMonthExpenses}
              prefix={<DollarOutlined />}
              valueStyle={{ color: '#1C4E80' }}
              formatter={expensesFormatter}
              valueRender={() => (
                <span>
                  {expensesFormatter(totalCurrentMonthExpenses)}
                  {(
                    <span style={{ fontSize: 'medium', marginLeft: 8, color: expenseDifference >= 0 ? '#f44336' : '#4caf50' }}>
                      {expenseDifference >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                      {Math.abs(Number(expensePercentageChange))}%
                    </span>
                  )}
                </span>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardTopBar;
