import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { createItem, deleteItem, readItems, updateItem } from '@directus/sdk';

// Define the Expense interface
export interface ExpenseData {
  id?: number;
  category: string;
  amount: number;
  date: string;
  description: string;
  property_id: {
    id: number;
    property_name: string;
    // Add other fields from the properties collection if needed
  };
  team_id?: number;
  date_created?: string;
  date_updated?: string;
  user_created?: string;
  user_updated?: string;
}

// Fetch all expenses
export const fetchExpenses = createAsyncThunk('expenses/fetchExpenses', async () => {
  const response = await directus.request<ExpenseData[]>(readItems('expenses',  {
    fields: ['*', 'property_id.id', 'property_id.property_name'] // Include all leasing fields and property data
}));
  return response;
});

// Add a new expense
export const addExpense = createAsyncThunk('expenses/addExpense', async (newExpense: ExpenseData) => {
  const response = await directus.request<ExpenseData>(createItem('expenses', newExpense));
  return {
    ...newExpense,
    id: response.id,
  };
});

// Update an existing expense
export const updateExpense = createAsyncThunk('expenses/updateExpense', async (updatedExpense: ExpenseData) => {
  const response = await directus.request<ExpenseData>(updateItem('expenses', updatedExpense.id!, updatedExpense));
  return {
    ...response,
    property_id: updatedExpense.property_id,
  };
});

// Delete an expense
export const deleteExpense = createAsyncThunk('expenses/deleteExpense', async (id: number) => {
  await directus.request<ExpenseData>(deleteItem('expenses', id));
  return id;
});

const expensesSlice = createSlice({
  name: 'expenses',
  initialState: {
    data: [] as ExpenseData[],
    status: 'idle',
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenses.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchExpenses.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchExpenses.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch expenses.';
      })
      .addCase(addExpense.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(updateExpense.fulfilled, (state, action) => {
        const index = state.data.findIndex((expense) => expense.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        state.data = state.data.filter((expense) => expense.id !== action.payload);
        state.error = null;
      });
  },
});

export default expensesSlice.reducer;
