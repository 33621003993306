export const PropertyType = {
    APARTMENT: 'apartment',
    HOUSE: 'house',
    VILLA: 'villa',
    CONDO: 'condo',
    TOWNHOUSE: 'townhouse',
    LAND: 'land',
    DUPLEX: 'duplex',
    STUDIO: 'studio',
    PENTHOUSE: 'penthouse',
    FARM: 'farm',
    WAREHOUSE: 'warehouse',
    COMMERCIAL_SPACE: 'commercial_space', 
    RETAIL_SPACE: 'retail_space',
};

export const PropertyStatus = {
    AVAILABLE: 'available',
    SOLD: 'sold',
    UNDER_CONSTRUCTION: 'under_construction',
    RENTED: 'rented',
    PENDING: 'pending',  
    RESERVED: 'reserved', 
    OFF_MARKET: 'off_market',
    MAINTENANCE: 'maintenance', 
    FORECLOSED: 'foreclosed', 
    LISTING_EXPIRED: 'listing_expired', 
    COMING_SOON: 'coming_soon', 
    TEMPORARILY_UNAVAILABLE: 'temporarily_unavailable',
};


export const LeasingStatus = {
    NEW: 'new',
    SIGNED: 'signed',
    CANCELLED: 'cancelled',
    PENDING: 'pending',
};

export const ScreeningStatus = {
    NOT_REQUESTED: 'not_requested',
    PENDING: 'pending',
    IN_REVIEW: 'in_review',
    PASSED: 'passed',
    FAILED: 'failed',
    REQUIRES_ADDITIONAL_INFO: 'requires_additional_info',
    BACKGROUND_CHECK_PENDING: 'background_check_pending',
    CREDIT_CHECK_PENDING: 'credit_check_pending',
    REJECTED: 'rejected',
    APPROVED_WITH_CONDITIONS: 'approved_with_conditions',
    CANCELLED: 'cancelled',
};

export const ExpenseCategories = ["Utilities", "Maintenance", "Supplies"]; // Example categories
