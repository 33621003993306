import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { 
    createItems,
    readItems,
    deleteItems,
    createItem
} from '@directus/sdk';

interface ReportData {
    id?: string;
    name: string;
    type: string;
    description: string;
    team_id?: number;
    created_at: string;
}

interface ReportsState {
    reports: ReportData[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ReportsState = {
    reports: [],
    status: 'idle',
    error: null
};

export const createReport = createAsyncThunk(
    'reports/createReport',
    async (reportData: ReportData) => {
        const response = await directus.request<ReportData>(createItem('reports', reportData));
        return response;
    }
);

export const fetchReports = createAsyncThunk(
    'reports/fetchReports',
    async () => {
        const response = await directus.request<ReportData[]>(readItems('reports'));
        return response;
    }
);

export const deleteReport = createAsyncThunk(
    'reports/deleteReport',
    async (reportId: string) => {
        await directus.request<ReportData>(deleteItems('reports', [reportId]));
        return reportId;
    }
);

const reportsSlice = createSlice({
    name: 'reports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createReport.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createReport.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reports.push(action.payload);
            })
            .addCase(createReport.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(fetchReports.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReports.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reports = action.payload;
            })
            .addCase(fetchReports.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(deleteReport.fulfilled, (state, action) => {
                state.reports = state.reports.filter(report => report.id !== action.payload);
            });
    }
});

export default reportsSlice.reducer; 