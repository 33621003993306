import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { createItem, deleteItem, readItems, updateItem } from '@directus/sdk';

export interface TenantData {
  id: number;
  full_name: string;
  email: string;
  phone_number: string;
  date_of_birth: string;
  address: string;
  team_id?: number;
  date_created?: string;
  date_updated?: string;
  user_created?: string;
  user_updated?: string;
}

export const fetchTenants = createAsyncThunk('tenants/fetchTenants', async () => {
  const response = await directus.request<TenantData[]>(readItems('tenants'));
  return response;
});

export const addTenant = createAsyncThunk('tenants/addTenant', async (newTenant: TenantData) => {
  const { id, ...tenantDataWithoutId } = newTenant;
  const response = await directus.request<TenantData>(createItem('tenants', tenantDataWithoutId));
  return {
    ...newTenant,
    id: response.id,
  };
});

export const updateTenant = createAsyncThunk('tenants/updateTenant', async (updatedTenant: TenantData) => {
  const response = await directus.request<TenantData>(updateItem('tenants', updatedTenant.id, updatedTenant));
  return response;
});

export const deleteTenant = createAsyncThunk('tenants/deleteTenant', async (id: number) => {
  await directus.request<TenantData>(deleteItem('tenants', id));
  return id;
});

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState: {
    data: [] as TenantData[],
    status: 'idle',
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch tenants.';
      })
      .addCase(addTenant.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.error = null;
      })
      .addCase(updateTenant.fulfilled, (state, action) => {
        const index = state.data.findIndex((tenant) => tenant.id === action.payload.id);
        if (index !== -1) {
          state.data[index] = action.payload;
        }
        state.error = null;
      })
      .addCase(deleteTenant.fulfilled, (state, action) => {
        state.data = state.data.filter((tenant) => tenant.id !== action.payload);
        state.error = null;
      });
  },
});

export default tenantsSlice.reducer;
