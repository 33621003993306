export const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
  };

export const formatPrecentage = (value: number) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(value);
  };

export  const revenueFormatter = (value: any) => {
    if (typeof value === 'number') {
      return `+ ${formatCurrency(value)}`;
    }
    return value;
  };

export const expensesFormatter = (value: any) => {
    if (typeof value === 'number') {
      return `- ${formatCurrency(value)}`;
    }
    return value;
  };