import React, { useState } from 'react';
import { Card, Col, Row, Statistic, Checkbox } from 'antd';
import { BankOutlined, PercentageOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState, AppDispatch } from '../redux/store';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
// import { fetchDebts } from '../redux/debtsSlice'; // Assuming you have a debtsSlice
import { formatCurrency, formatPrecentage } from '../utils/currencyFormatter';

const OverviewTopBar: React.FC = () => {
  const [includeExpenses, setIncludeExpenses] = useState(true);
  const properties = useSelector((state: RootState) => state.properties.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const expenses = useSelector((state: RootState) => state.expenses.data);
  // const debts = useSelector((state: RootState) => state.debts.data);
  // const status = useSelector((state: RootState) => state.properties.status);

  // const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (status === 'idle') {
  //     dispatch(fetchProperties());
  //     dispatch(fetchLeasings());
  //     // dispatch(fetchDebts());
  //   }
  // }, [status, dispatch]);

  // Calculate Total Assets Worth
  const totalAssetsWorth = properties.reduce((sum, property) => sum + (property.purchase_cost || 0), 0);

  // Calculate Expected Yearly Yield
  const totalYearlyRent = leasings.reduce((sum, lease) => sum + ((lease.monthly_rent || 0) * 12), 0);
  const totalYearlyExpenses = includeExpenses ? 
    expenses.reduce((sum, expense) => sum + (expense.amount || 0), 0) : 0;
  const netYearlyIncome = totalYearlyRent - totalYearlyExpenses;
  const expectedYearlyYield = totalAssetsWorth > 0 ? (netYearlyIncome / totalAssetsWorth) * 100 : 0;

  // Calculate Total Debts
  const totalDebts = 1 //debts.reduce((sum, debt) => sum + (debt.amount || 0), 0);

  // Handlers for navigation
  const handleAssetsClick = () => navigate('/properties');
  const handleYieldClick = () => navigate('/leasings');
  const handleDebtsClick = () => navigate('/debts');
  const handleRevenueClick = () => navigate('/leasings');
  const handleExpensesClick = () => navigate('/expenses');

  return (
    <div style={{ padding: '8px', background: '#fff' }}>
      <Row gutter={16} >
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Estimated Yearly Revenue"
              value={totalYearlyRent}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleRevenueClick}
                >
                  {formatCurrency(totalYearlyRent)}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Total Assets Worth"
              value={totalAssetsWorth}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleAssetsClick}
                >
                  {formatCurrency(totalAssetsWorth)}
                </span>
              )}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="Estimated Yearly Yield"
              value={expectedYearlyYield}
              valueStyle={{ color: '#1C4E80' }}
              precision={2}
              valueRender={() => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span
                    style={{ cursor: 'pointer', color: '#1C4E80', whiteSpace: 'nowrap' }}
                    onClick={handleYieldClick}
                  >
                    {formatPrecentage(expectedYearlyYield)}%
                  </span>
                  <Checkbox
                    checked={includeExpenses}
                    onChange={(e) => setIncludeExpenses(e.target.checked)}
                    style={{ fontSize: '12px' }}
                  >
                    <span style={{ fontSize: '12px' }}>w/Expenses</span>
                  </Checkbox>
                </div>
              )}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card bordered={false}>
            <Statistic
              title="YTD Expenses"
              value={totalYearlyExpenses}
              valueStyle={{ color: '#1C4E80' }}
              valueRender={() => (
                <span
                  style={{ cursor: 'pointer', color: '#1C4E80' }}
                  onClick={handleExpensesClick}
                >
                  {formatCurrency(totalYearlyExpenses)}
                </span>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTopBar;