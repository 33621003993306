import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AuthenticatedLayout from './components/AuthenticatedLayout';
import UnauthenticatedLayout from './components/UnauthenticatedLayout';
import PageContent from './components/PageContent';
import LoginPage from './views/Login';
import SignupPage from './views/SignupPage';
import LandingPage from './views/LandingPage';
import { jwtDecode } from 'jwt-decode';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AuthCallback from './views/AuthCallback';
import directus from './utils/directusClient';
import { readMe } from '@directus/sdk';
import { getAppUrl } from './utils/urlHelper';
import ReactGA from 'react-ga4';

// Define the type for the JWT token's payload
interface DecodedToken {
  exp: number;
}

const isProd = process.env.REACT_APP_ENV === 'prod';
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-LK6SY9JQ49';

// Only initialize GA in production
if (isProd && TRACKING_ID) {
  ReactGA.initialize(TRACKING_ID);
}

// Update Analytics component to check for production environment
const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (isProd && TRACKING_ID) {
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, [location]);

  return null;
};

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const isProd = process.env.REACT_APP_ENV === 'prod';
  const isAppDomain = window.location.hostname === 'app.blurben.com';

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // If we're on blurben.com (not app.blurben.com) and not in development, render only the landing page
  if (!isAppDomain && isProd) {
    return (
      <BrowserRouter>
        <Analytics />
        <Routes>
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // Regular app routes for app.blurben.com or development
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Analytics />
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={
            <PublicOnlyRoute>
              <UnauthenticatedLayout>
                <LoginPage />
              </UnauthenticatedLayout>
            </PublicOnlyRoute>
          } />
          <Route path="/signup" element={
            <PublicOnlyRoute>
              <UnauthenticatedLayout>
                <SignupPage />
              </UnauthenticatedLayout>
            </PublicOnlyRoute>
          } />
          
          {/* Protected routes */}
          <Route path="/*" element={
            <ProtectedRoute collapsed={collapsed} handleCollapse={handleCollapse} />
          } />

          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

// Add this new custom hook
const useSessionCheck = (redirectTo: string, shouldBeAuthenticated: boolean) => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkSession = async () => {
      try {
        const user = await directus.request<any>(readMe());
        if (shouldBeAuthenticated && !user) {
          navigate('/login');
        } else if (!shouldBeAuthenticated && user) {
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Session check failed:', error);
        if (shouldBeAuthenticated) {
          navigate('/login');
        }
      }
    };

    checkSession();
    
    // Only set up interval for protected routes
    if (shouldBeAuthenticated) {
      const intervalId = setInterval(checkSession, 5 * 60 * 1000);
      return () => clearInterval(intervalId);
    }
  }, [navigate, redirectTo, shouldBeAuthenticated]);
};

// Update ProtectedRoute to use the hook
const ProtectedRoute = ({ collapsed, handleCollapse }: any) => {
  useSessionCheck('/login', true);

  return (
    <AuthenticatedLayout collapsed={collapsed} handleCollapse={handleCollapse}>
      <PageContent />
    </AuthenticatedLayout>
  );
};

// Update PublicOnlyRoute to use the hook
const PublicOnlyRoute = ({ children }: { children: React.ReactNode }) => {
  useSessionCheck('/dashboard', false);
  
  return <>{children}</>;
};

// const isTokenExpired = (token: string): boolean => {
//   try {
//     const decodedToken = jwtDecode<DecodedToken>(token);
//     const currentTime = Date.now() / 1000;
    
//     if (decodedToken.exp === undefined) {
//       return true;
//     }
//     return decodedToken.exp < currentTime;
//   } catch (error) {
//     console.error("Error decoding token:", error);
//     return true;
//   }
// };

export default App;
