import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Row, Col, Card, Button } from 'antd';
import { Pie } from '@ant-design/charts';
import DashboardTopBar from '../components/DashboardTopBar';
import RecentActivity from '../components/RecentActivity';
import SearchBox from '../components/SearchBox';
import { RootState } from '../redux/store';
import { PropertyData } from "../redux/propertiesSlice";
import AISuggestions from '../components/AISuggestions';

const { Content } = Layout;

const calculateOccupancyRate = (properties: PropertyData[]) => {
  const totalProperties = properties.length;
  const occupiedProperties = properties.filter(p => p.status === 'rented').length;
  const unoccupiedProperties = totalProperties - occupiedProperties;

  return [
    { type: 'occupied', value: occupiedProperties },
    { type: 'vacant', value: unoccupiedProperties },
  ];
};

const Dashboard: React.FC = () => {
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const properties = useSelector((state: RootState) => state.properties.data);
  const chartData = calculateOccupancyRate(properties);

  const pieConfig = {
    radius: 1.2,
    innerRadius: 0.8,
    data: chartData,
    angleField: 'value',
    colorField: 'type',
    label: {
      offset: '-50%',
      content: '{value}%',
      style: {
        fontSize: 18,
        textAlign: 'center',
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column' }}>
      <DashboardTopBar />
      <Content style={{ flex: 1, padding: '20px' }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card title="Property Occupancy Rate" bordered={false}>
              <div style={{ height: '150px' }}>
                <Pie {...pieConfig} />
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <Card 
              title="Recent Activities" 
              extra={
                <Button type="link" onClick={() => setIsActivityModalVisible(true) } style={{ padding: 0, marginRight: '-5px', color: '#1C4E80' }}>
                  More
                </Button>
              }
              bordered={false}
            >
              <div style={{ height: '150px' }}>
                <RecentActivity 
                  isModalVisible={isActivityModalVisible}
                  setIsModalVisible={setIsActivityModalVisible}
                />
              </div>
            </Card>
          </Col>
          <Col span={8}>
            <div style={{ height: '150px' }}>
              <AISuggestions />
            </div>
          </Col>
        </Row>
        {/* Add more sections as needed */}
      </Content>
    </Layout>
  );
};

export default Dashboard;