import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { readItems, createItem, deleteItem, updateItem } from '@directus/sdk'; // Assuming createItem exists in directusClient



export interface PropertyData {
  id: number;
  property_name: string;
  full_address: string;
  size: number;
  type: string;
  status: string;
  purchase_cost: number;
  purchase_date: string;
  estimated_cost: number;
  team_id?: number;
  date_created?: string;
  date_updated?: string;
  user_created?: string;
  user_updated?: string;
}

interface PropertiesState {
  data: PropertyData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PropertiesState = {
  data: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch properties
export const fetchProperties = createAsyncThunk('properties/fetchProperties', async () => {
  console.log("Fetching properties...");
  const response = await directus.request<PropertyData[]>(readItems('properties'));
  console.log("Fetched properties:", response);
  return response;
});

// Async thunk to add a new property
export const addProperty = createAsyncThunk(
  'properties/addProperty',
  async (newProperty: PropertyData) => {
    const { id, ...propertyDataWithoutId } = newProperty;
    const response = await directus.request<PropertyData>(createItem('properties', propertyDataWithoutId));
    return response;
  }
);

export const updateProperty = createAsyncThunk(
  'properties/updateProperty',
  async (updatedProperty: PropertyData) => {
    const response =  await directus.request<PropertyData>(updateItem('properties', updatedProperty.id, updatedProperty));
    return response; // Return the updated property
  }
);

export const deleteProperty = createAsyncThunk(
  'properties/deleteProperty',
  async (id: number) => {
    await directus.request<PropertyData>(deleteItem('properties', id));
    return id
  }
);

const propertiesSlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch properties';;
      })
      .addCase(addProperty.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data.push(action.payload); // Assuming the API returns the newly added property
        state.error = null;
      })
      .addCase(addProperty.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(updateProperty.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateProperty.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = state.data.map((property) =>
          property.id === action.payload.id ? action.payload : property
        );
        state.error = null;
      })
      .addCase(updateProperty.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
     .addCase(deleteProperty.pending, (state) => {
        state.status = 'loading'; // Optionally, you can add loading state handling for delete
        state.error = null;
      })
      .addCase(deleteProperty.fulfilled, (state, action) => {
        state.data = state.data.filter((property) => property.id !== action.payload); // Remove the property by id
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(deleteProperty.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string || action.error.message || null; // Handle the error message
      });
  },
});


export default propertiesSlice.reducer;
