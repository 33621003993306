import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { readFiles, uploadFiles, deleteFile } from '@directus/sdk';

const filesFolderId = process.env.REACT_APP_ENV === 'prod' 
  ? "0b368f1e-84a4-42d8-8a5f-11ebac1a149a" 
  : "48503dfd-7b2c-4916-9080-d74055abd2dd";

// Define types for the state and payload
interface UploadFilePayload {
  file: File;
  entityId: number;
  entityType: string;
  teamId: number;
  folderName?: string;
}

interface FetchFilesPayload {
  entityId: number;
  entityType: string;
  teamId: number;
  folderName?: string;
}

interface FileState {
  files: any[]; // Replace with your actual file type definition
  uploadStatus: 'idle' | 'loading' | 'succeeded' | 'failed' | null;
  fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed' | null;
  deleteStatus: 'idle' | 'loading' | 'succeeded' | 'failed' | null;
  error: string | null;
}

const initialState: FileState = {
  files: [],
  uploadStatus: null,
  fetchStatus: null,
  deleteStatus: null,
  error: null,
};

// Existing thunks remain the same
export const uploadPropertyFile = createAsyncThunk(
  'files/uploadPropertyFile',
  async ({ file, entityId, entityType, teamId, folderName = 'properties' }: UploadFilePayload) => {
    const formData = new FormData();
    formData.append('folder', filesFolderId);
    formData.append('entity_type', entityType);
    formData.append('entity_id', entityId.toString());
    formData.append('team_id', teamId.toString());
    formData.append('file', file);

    const response = await directus.request(uploadFiles(formData));
    return response;
  }
);

export const fetchPropertyFiles = createAsyncThunk(
  'files/fetchPropertyFiles',
  async () => {
    const response = await directus.request(readFiles({
      filter: {
        'folder': { _eq: filesFolderId },
      },
    })) as any;

    return response;
  }
);

// New thunk for deleting a file
export const deletePropertyFile = createAsyncThunk(
  'files/deletePropertyFile',
  async (fileId: string) => {
    await directus.request(deleteFile(fileId));
    return fileId;
  }
);

// Redux slice for file operations
const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle file upload
      .addCase(uploadPropertyFile.pending, (state) => {
        state.uploadStatus = 'loading';
        state.error = null;
      })
      .addCase(uploadPropertyFile.fulfilled, (state, action: PayloadAction<any>) => {
        state.uploadStatus = 'succeeded';
        state.files.push(action.payload);
      })
      .addCase(uploadPropertyFile.rejected, (state, action: PayloadAction<any>) => {
        state.uploadStatus = 'failed';
        state.error = action.payload;
      })

      // Handle file fetching
      .addCase(fetchPropertyFiles.pending, (state) => {
        state.fetchStatus = 'loading';
        state.error = null;
      })
      .addCase(fetchPropertyFiles.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.fetchStatus = 'succeeded';
        state.files = action.payload;
      })
      .addCase(fetchPropertyFiles.rejected, (state, action: PayloadAction<any>) => {
        state.fetchStatus = 'failed';
        state.error = action.payload;
      })

      // Handle file deletion
      .addCase(deletePropertyFile.pending, (state) => {
        state.deleteStatus = 'loading';
        state.error = null;
      })
      .addCase(deletePropertyFile.fulfilled, (state, action: PayloadAction<string>) => {
        state.deleteStatus = 'succeeded';
        state.files = state.files.filter(file => file.id !== action.payload);
      })
      .addCase(deletePropertyFile.rejected, (state, action: PayloadAction<any>) => {
        state.deleteStatus = 'failed';
        state.error = action.payload;
      });
  },
});

export default filesSlice.reducer;