import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Progress, message, Select } from 'antd';
import { addExpense, ExpenseData } from '../../redux/expensesSlice'; // Adjust the path as necessary
import { RootState } from '../../redux/store';
import {ExpenseCategories} from '../../utils/options'

interface AddExpenseWizardProps {
    visible: boolean;
    onCancel: () => void;
}

const AddExpenseWizard: React.FC<AddExpenseWizardProps> = ({ visible, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formValues, setFormValues] = useState<any>({});
    const properties = useSelector((state: RootState) => state.properties.data); // Adjust as necessary
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.data);

    
    // const categories = ["Utilities", "Maintenance", "Supplies"]; // Example categories

    const steps = [
        {
            title: 'Property',
            content: (
                <Form.Item
                    name="property_id"
                    label="Property"
                    rules={[{ required: true, message: 'Please select a property' }]}
                >
                    <Select placeholder="Select a property">
                        {properties.map(property => (
                            <Select.Option key={property.id} value={property.id}>
                                {property.property_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: 'Category',
            content: (
                <Form.Item
                    name="category"
                    label="Category"
                    rules={[{ required: true, message: 'Please select a category' }]}
                >
                    <Select placeholder="Select a category">
                        {ExpenseCategories.map(category => (
                            <Select.Option key={category} value={category}>
                                {category}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: 'Amount',
            content: (
                <Form.Item
                    name="amount"
                    label="Amount ($)"
                    rules={[{ required: true, message: 'Please enter the amount' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
            )
        },
        {
            title: 'Date',
            content: (
                <Form.Item
                    name="date"
                    label="Date"
                    rules={[{ required: true, message: 'Please select the expense date' }]}
                >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
            )
        },
        {
            title: 'Description',
            content: (
                <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please enter the expense description' }]}
                >
                    <Input.TextArea rows={4} style={{ width: '100%' }} />
                </Form.Item>
            )
        }
        ,
    ];

    const handleAddExpense = async (values: any) => {
        try {
            const expenseData: ExpenseData = {
                // id: -1, // Assuming new expense starts with an id of -1
                description: values.description,
                category: values.category,
                date: values.date ? values.date.format('YYYY-MM-DD') : '',
                amount: values.amount,
                property_id: {
                    id: values.property_id,
                    property_name: properties.find(p => p.id === values.property_id)?.property_name || ''
                    // property_name:"test"
                },
                team_id: user?.team_id
            };

            await dispatch(addExpense(expenseData) as any);
            message.success("Expense created successfully");
            onCancel(); // Close the modal on success
            form.resetFields(); // Reset form fields after submission
            setCurrentStep(0); // Reset the step to the beginning
        } catch (error) {
            console.error('Error adding expense:', error);
            message.error("Failed to create expense record");
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = (values: any) => {
        setFormValues({ ...formValues, ...values });
        if (currentStep === steps.length - 1) {
            handleAddExpense({ ...formValues, ...values });
        } else {
            nextStep();
        }
    };

    const handleCancel = () => {
        onCancel(); // Close the modal
        form.resetFields(); // Reset form fields after cancel
        setCurrentStep(0); // Reset the step to the beginning
    };

    return (
        <Modal
            title="Add New Expense"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            centered
        >
            <Progress
                percent={(currentStep / (steps.length - 1)) * 100}
                showInfo={false}
                style={{ marginBottom: '24px' }}
            />

            <Form layout="vertical" onFinish={handleFinish} form={form}>
                {steps[currentStep].content}
                <div style={{ marginTop: 24 }}>
                    {currentStep > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={prevStep}>
                            Previous
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddExpenseWizard;
