import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Button, Space, Modal, Form, Input, InputNumber, notification, Select, Divider, Upload, DatePicker, List } from 'antd';
import { HomeOutlined, ToolOutlined, PlusOutlined, AppstoreAddOutlined, SearchOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, InboxOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchExpenses, updateExpense, deleteExpense } from '../redux/expensesSlice';
import { RootState, AppDispatch } from '../redux/store';
import { ExpenseData } from '../redux/expensesSlice';
import { ExpenseCategories } from '../utils/options';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';
import { fetchPropertyFiles, uploadPropertyFile, deletePropertyFile } from '../redux/filesSlice';
import { readAssetRaw } from '@directus/sdk';
import directus from '../utils/directusClient';
import { UploadChangeParam } from 'antd/es/upload';

type FileType = {
  id: string;
  filename_download: string;
  type: string;
  filesize: number;
};

const Expenses: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const expenses = useSelector((state: RootState) => state.expenses.data);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const files = useSelector((state: RootState) =>
    state.files.files.filter(file => file.entity_type === 'expense')
  );  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageUrls, setImageUrls] = useState<Record<number, string[]>>({});
  const [editingExpense, setEditingExpense] = useState<ExpenseData | null>(null);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any[]>([]);
  const filesRef = useRef(files);
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);



  useEffect(() => {
    dispatch(fetchExpenses());
    if (editingExpense) {
      dispatch(fetchPropertyFiles());
    }
  }, [dispatch, editingExpense]);

  useEffect(() => {
    if (Object.keys(imageUrls).length === 0 || Object.values(imageUrls).flat().length !== files.length) {
      filesRef.current = files;
      if (files.length > 0) {
        fetchImages();
      }
    }
  }, [files]);

  useEffect(() => {
    return () => {
      // Cleanup URLs when component unmounts
      Object.values(imageUrls).forEach(urls => {
        urls.forEach(url => URL.revokeObjectURL(url));
      });
    };
  }, [imageUrls]);

  const getCategoryIcon = (category: string) => {
    switch (category) {
      case 'Maintenance':
        return <ToolOutlined />;
      case 'Utilities':
        return <AppstoreAddOutlined />;
      case 'Cleaning Services':
        return <HomeOutlined />;
      case 'Pest Control':
        return <SearchOutlined />;
      default:
        return <HomeOutlined />;
    }
  };

  const fetchImages = useCallback(async () => {
    const urlMap: Record<number, string[]> = {};

    for (const file of filesRef.current) {
      try {
        const response = await directus.request(readAssetRaw(file.id));
        const reader = response.getReader();
        const chunks: Uint8Array[] = [];

        let done = false;
        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;
          if (value) {
            chunks.push(value);
          }
        }

        const blob = new Blob(chunks, { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        
        const entityId = file.entity_id;
        if (!urlMap[entityId]) {
          urlMap[entityId] = [];
        }
        urlMap[entityId].push(url);
      } catch (error) {
        console.error(`Error fetching image for file ${file.id}:`, error);
      }
    }

    setImageUrls(urlMap);
  }, []);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleEdit = (expense: ExpenseData) => {
    setEditingExpense({ ...expense });
    setIsModalVisible(true);
    form.setFieldsValue({
      ...expense,
      property_name: expense.property_id?.property_name,
      date: dayjs(expense.date),
    });
    dispatch(fetchPropertyFiles());
  };

  const handleDelete = (id: number) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this expense?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteExpense(id))
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Expense deleted',
              description: 'The expense has been successfully deleted.',
            });
          })
          .catch((error) => {
            notification.error({
              message: 'Delete failed',
              description: error.message || 'An error occurred while deleting the expense.',
            });
          });
      },
    });
  };

  const handleConfirmDelete = async () => {
    if (fileToDelete) {
      try {
        await dispatch(deletePropertyFile(fileToDelete.uid));
        notification.success({ message: 'Photo deleted successfully' });
        dispatch(fetchPropertyFiles());
      } catch (error: any) {
        notification.error({ message: 'Failed to delete photo', description: error.message });
      } finally {
        setIsDeleteModalVisible(false);
        setFileToDelete(null);
      }
    }
  };

  const showDeleteConfirm = (file: any) => {
    setFileToDelete(file);
    setIsDeleteModalVisible(true);
    
  };

  const handleModalOk = async (values: any) => {
    if (editingExpense) {
      await dispatch(updateExpense({ ...editingExpense, ...values }))
        .unwrap()
        .then(() => {
          // fileList.forEach(file => {
          //   dispatch(uploadPropertyFile({
          //     file: file.originFileObj,
          //     entityId: editingExpense.id || 0,
          //     entityType: 'expense',
          //     teamId: editingExpense.team_id || 0,
          //   }));
          // });
          notification.success({
            message: 'Expense updated',
            description: 'The expense has been successfully updated.',
          });
          setIsModalVisible(false);
          setFileList([]);
        })
        .catch((error) => {
          notification.error({
            message: 'Update failed',
            description: error.message || 'An error occurred while updating the expense.',
          });
        });
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleUploadChange = ({ fileList: newFileList }: UploadChangeParam) => {
    setFileList(newFileList);
  };

const handlePhotoUpload = async (options: any, record: ExpenseData) => {
  const { file } = options;

    try {
      await dispatch(uploadPropertyFile({ file, entityId: record.id || 0, entityType: "expense", teamId: record!.team_id || 0, folderName: 'properties' }));
      notification.success({ message: 'Photo uploaded successfully' });
      dispatch(fetchPropertyFiles());
    } catch (error: any) {
      notification.error({ message: 'Failed to upload photo', description: error.message });
    }
  };

  const isImageFile = (fileType: string) => {
    return fileType.startsWith('image/');
  };

  const handleDownload = async (file: FileType) => {
    try {
      const response = await directus.request(readAssetRaw(file.id));
      const reader = response.getReader();
      const chunks: Uint8Array[] = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        if (value) chunks.push(value);
      }

      const blob = new Blob(chunks, { type: file.type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.filename_download;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: 'Download failed',
        description: 'Failed to download the file'
      });
    }
  };

  const columns: ColumnsType<ExpenseData> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: ExpenseCategories.map(category => ({ text: category, value: category })),
      onFilter: (value, record) => record.category === value,
      render: (category: string) => (
        <Space>
          {getCategoryIcon(category)} <span style={{ fontWeight: 'bold' }}>{category}</span>
        </Space>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount: number) => (
        <span style={{ fontWeight: 'bold' }}>${amount}</span>
      ),
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Property',
      dataIndex: ['property_id', 'property_name'],
      key: 'property_name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined style={{ color: 'black' }} />}
            size="small"
            type='link'
            onClick={() => handleEdit(record)}
          />
          <Button
            icon={<DeleteOutlined style={{ color: 'black' }} />}
            size="small"
            type='link'
            onClick={() => handleDelete(record.id || -1)}
          />
        </Space>
      ),
    },
  ];

  const renderUploadComponent = (record: ExpenseData) => {
    const relatedFiles = files.filter(file => file.entity_id === record.id);
    const recordImageUrls = imageUrls[record.id || 0] || [];
    
    return (
      <div>
        <div style={{ marginBottom: 16, width: '300px', height: '100px' }}>
          <Upload.Dragger
            customRequest={(options) => handlePhotoUpload(options, record)}
            showUploadList={false}
            accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,image/*"
            style={{ padding: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <p className="ant-upload-drag-icon" style={{ marginTop: '0', marginBottom: '4px', textAlign: 'center' }}>
              <InboxOutlined />
            </p>
            <p className="ant-upload-text" style={{ marginBottom: '0', textAlign: 'center' }}>Click or drag files to upload</p>
          </Upload.Dragger>
        </div>

        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Image files */}
          <div style={{ flex: 1 }}>
            <h5>Images:</h5>
            {relatedFiles.filter(file => isImageFile(file.type)).length > 0 ? (
              <Upload
                listType="picture-card"
                fileList={
                  relatedFiles
                    .filter(file => isImageFile(file.type))
                    .map((file, index) => ({
                      uid: file.id,
                      name: file.filename_download,
                      status: 'done',
                      url: recordImageUrls[index],
                      type: file.type,
                    }))
                }
                showUploadList={{ 
                  showPreviewIcon: true, 
                  showRemoveIcon: true,
                  showDownloadIcon: true
                }}
                customRequest={(options) => handlePhotoUpload(options, record)}
                onRemove={file => showDeleteConfirm({ ...file, id: file.uid })}
                onPreview={handlePreview}
                onDownload={(file) => {
                  const originalFile = relatedFiles.find(f => f.id === file.uid);
                  if (originalFile) handleDownload(originalFile);
                }}
              />
            ) : (
              <div style={{ textAlign: 'center', padding: '20px', color: '#999' }}>
                <p style={{ margin: 0 }}>No images uploaded yet</p>
              </div>
            )}
          </div>

          {/* Document files */}
          <div style={{ flex: 1 }}>
            <h5>Documents:</h5>
            <List
              dataSource={relatedFiles.filter(file => !isImageFile(file.type))}
              locale={{ 
                emptyText: (
                  <div style={{ textAlign: 'center', padding: '5px', color: '#999' }}>
                    <p style={{ margin: 0 }}>No documents uploaded yet</p>
                  </div>
                ) 
              }}
              renderItem={file => (
                <List.Item
                  actions={[
                    <Button 
                      icon={<DownloadOutlined />} 
                      onClick={() => handleDownload(file)}
                    />,
                    <Button 
                      icon={<DeleteOutlined />} 
                      onClick={() => showDeleteConfirm({ ...file, uid: file.id })}
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={file.filename_download}
                    description={`${(file.filesize / 1024).toFixed(2)} KB`}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>

        {/* Existing modals */}
        <Modal
          title="Confirm Delete"
          visible={isDeleteModalVisible}
          onOk={handleConfirmDelete}
          onCancel={() => setIsDeleteModalVisible(false)}
          okText="Delete"
          cancelText="Cancel"
          centered
        >
          <p>Are you sure you want to delete this file?</p>
        </Modal>

        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={handleCancelPreview}
          centered
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  };
  

  const expandedRowRender = (record: ExpenseData) => {
    return (
      <div>
        <h4>Notes:</h4>
        <p>{record.description}</p>
        <Divider />
        {/* <h4>Uploaded Files:</h4> */}
          <div>
            {renderUploadComponent(record)}
          </div>
      </div>
    );
  };

  return (
    <>
      <h1>Expenses</h1>
      <Table
        columns={columns}
        dataSource={expenses}
        expandable={{ expandedRowRender }}
        rowKey="id"
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={editingExpense ? 'Edit Expense' : ''}
        visible={isModalVisible}
        // onOk={() => form.submit()}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleModalOk}>
          <Form.Item
              label="Property"
              name="property_name"
              rules={[{ required: true, message: 'Please input the property!' }]}
            >
            <Input readOnly style={{ color: 'grey' }} />
          </Form.Item>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="category" label="Category" rules={[{ required: true }]}>
            <Select>
              {ExpenseCategories.map((category) => (
                <Select.Option key={category} value={category}>{category}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="date" label="Date" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="Upload Files">
            {editingExpense && renderUploadComponent(editingExpense)}
          </Form.Item>  
          <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>        
        </Form>
      </Modal>
    </>
  );
};

export default Expenses;
