import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Button, Space, Modal, Form, Input, notification, DatePicker, Upload, Divider, List } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTenants, addTenant, updateTenant, deleteTenant, TenantData } from '../redux/tenantsSlice';
import { RootState, AppDispatch } from '../redux/store';
import { EditOutlined, DeleteOutlined, PlusOutlined, InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { fetchPropertyFiles, uploadPropertyFile, deletePropertyFile } from '../redux/filesSlice';
import { readAssetRaw } from '@directus/sdk';
import directus from '../utils/directusClient';
import { UploadChangeParam } from 'antd/es/upload';
import dayjs from 'dayjs';
import { ColumnsType } from 'antd/es/table';

const { confirm } = Modal;
const phoneNumberPattern = /^(?:\+\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

type FileType = {
  id: string;
  filename_download: string;
  type: string;
  filesize: number;
};

const Tenants: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const tenants = useSelector((state: RootState) => state.tenants.data);
  const error = useSelector((state: RootState) => state.tenants.error);
  const files = useSelector((state: RootState) =>
    state.files.files.filter(file => file.entity_type === 'tenant')
  );
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingTenant, setEditingTenant] = useState<TenantData | null>(null);
  const [form] = Form.useForm();
  
  // Document preview states
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imageUrls, setImageUrls] = useState<Record<number, string[]>>({});
  const [fileList, setFileList] = useState<any[]>([]);
  const filesRef = useRef(files);
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    dispatch(fetchTenants());
    if (editingTenant) {
      dispatch(fetchPropertyFiles());
    }
  }, [dispatch, editingTenant]);

  useEffect(() => {
    if (Object.keys(imageUrls).length === 0 || Object.values(imageUrls).flat().length !== files.length) {
      filesRef.current = files;
      if (files.length > 0) {
        fetchImages();
      }
    }
  }, [files]);

  useEffect(() => {
    return () => {
      // Cleanup URLs when component unmounts
      Object.values(imageUrls).forEach(urls => {
        urls.forEach(url => URL.revokeObjectURL(url));
      });
    };
  }, [imageUrls]);

  const fetchImages = useCallback(async () => {
    const urlMap: Record<number, string[]> = {};

    for (const file of filesRef.current) {
      try {
        const response = await directus.request(readAssetRaw(file.id));
        const reader = response.getReader();
        const chunks: Uint8Array[] = [];

        let done = false;
        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;
          if (value) {
            chunks.push(value);
          }
        }

        const blob = new Blob(chunks, { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        
        const entityId = file.entity_id;
        if (!urlMap[entityId]) {
          urlMap[entityId] = [];
        }
        urlMap[entityId].push(url);
      } catch (error) {
        console.error(`Error fetching image for file ${file.id}:`, error);
      }
    }

    setImageUrls(urlMap);
  }, []);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handlePhotoUpload = async (options: any, record: TenantData) => {
    const { file } = options;

    try {
      await dispatch(uploadPropertyFile({
        file,
        entityId: record.id,
        entityType: "tenant",
        teamId: record.team_id || 0,
        folderName: 'tenants'
      }));
      notification.success({ message: 'Document uploaded successfully' });
      dispatch(fetchPropertyFiles());
    } catch (error: any) {
      notification.error({ message: 'Failed to upload document', description: error.message });
    }
  };

  const handleConfirmDelete = async () => {
    if (fileToDelete) {
      try {
        await dispatch(deletePropertyFile(fileToDelete.uid));
        notification.success({ message: 'Document deleted successfully' });
        dispatch(fetchPropertyFiles());
      } catch (error: any) {
        notification.error({ message: 'Failed to delete document', description: error.message });
      } finally {
        setIsDeleteModalVisible(false);
        setFileToDelete(null);
      }
    }
  };

  const showDeleteConfirm = (file: any) => {
    setFileToDelete(file);
    setIsDeleteModalVisible(true);
  };

  const handleDownload = async (file: FileType) => {
    try {
      const response = await directus.request(readAssetRaw(file.id));
      const reader = response.getReader();
      const chunks: Uint8Array[] = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        if (value) chunks.push(value);
      }

      const blob = new Blob(chunks, { type: file.type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.filename_download;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: 'Download failed',
        description: 'Failed to download the file'
      });
    }
  };

  const isImageFile = (fileType: string) => {
    return fileType.startsWith('image/');
  };

  const renderUploadComponent = (record: TenantData) => {
    const relatedFiles = files.filter(file => file.entity_id === record.id);
    const recordImageUrls = imageUrls[record.id || 0] || [];
    
    return (
      <div>
        <div style={{ marginBottom: 16, width: '300px', height: '100px' }}>
          <Upload.Dragger
            customRequest={(options) => handlePhotoUpload(options, record)}
            showUploadList={false}
            accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,image/*"
            style={{ padding: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <p className="ant-upload-drag-icon" style={{ marginTop: '0', marginBottom: '4px', textAlign: 'center' }}>
              <InboxOutlined />
            </p>
            <p className="ant-upload-text" style={{ marginBottom: '0', textAlign: 'center' }}>Click or drag files to upload</p>
          </Upload.Dragger>
        </div>

        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Image files */}
          <div style={{ flex: 1 }}>
            <h5>Images:</h5>
            {relatedFiles.filter(file => isImageFile(file.type)).length > 0 ? (
              <Upload
                listType="picture-card"
                fileList={
                  relatedFiles
                    .filter(file => isImageFile(file.type))
                    .map((file, index) => ({
                      uid: file.id,
                      name: file.filename_download,
                      status: 'done',
                      url: recordImageUrls[index],
                      type: file.type,
                    }))
                }
                showUploadList={{ 
                  showPreviewIcon: true, 
                  showRemoveIcon: true,
                  showDownloadIcon: true
                }}
                customRequest={(options) => handlePhotoUpload(options, record)}
                onRemove={file => showDeleteConfirm({ ...file, id: file.uid })}
                onPreview={handlePreview}
                onDownload={(file) => {
                  const originalFile = relatedFiles.find(f => f.id === file.uid);
                  if (originalFile) handleDownload(originalFile);
                }}
              />
            ) : (
              <div style={{ textAlign: 'center', padding: '20px', color: '#999' }}>
                <p style={{ margin: 0 }}>No images uploaded yet</p>
              </div>
            )}
          </div>

          {/* Document files */}
          <div style={{ flex: 1 }}>
            <h5>Documents:</h5>
            <List
              dataSource={relatedFiles.filter(file => !isImageFile(file.type))}
              locale={{ 
                emptyText: (
                  <div style={{ textAlign: 'center', padding: '5px', color: '#999' }}>
                    <p style={{ margin: 0 }}>No documents uploaded yet</p>
                  </div>
                ) 
              }}
              renderItem={file => (
                <List.Item
                  actions={[
                    <Button 
                      icon={<DownloadOutlined />} 
                      onClick={() => handleDownload(file)}
                    />,
                    <Button 
                      icon={<DeleteOutlined />} 
                      onClick={() => showDeleteConfirm({ ...file, uid: file.id })}
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={file.filename_download}
                    description={`${(file.filesize / 1024).toFixed(2)} KB`}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>

        <Modal
          title="Confirm Delete"
          open={isDeleteModalVisible}
          onOk={handleConfirmDelete}
          onCancel={() => setIsDeleteModalVisible(false)}
          okText="Delete"
          cancelText="Cancel"
          centered
        >
          <p>Are you sure you want to delete this file?</p>
        </Modal>

        <Modal
          open={previewVisible}
          footer={null}
          onCancel={handleCancelPreview}
          centered
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  };

  // Existing code remains the same...
  const handleEdit = (tenant: TenantData) => {
    setEditingTenant(tenant);
    setIsModalVisible(true);
    form.setFieldsValue({
      ...tenant,
      date_of_birth: tenant.date_of_birth ? dayjs(tenant.date_of_birth) : null,
    });
  };

  const handleDelete = (id: number) => {
    confirm({
      title: 'Are you sure you want to delete this tenant record?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteTenant(id))
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Tenant deleted',
              description: 'The tenant has been successfully deleted.',
            });
          })
          .catch((error: any) => {
            notification.error({
              message: 'Delete failed',
              description: error.message || 'An error occurred while deleting the tenant.',
            });
          });
      },
    });
  };

  const handleModalOk = (values: any) => {
    const updatedValues = {
      ...values,
      date_of_birth: values.date_of_birth ? values.date_of_birth.format('YYYY-MM-DD') : null,
    };

    if (editingTenant) {
      dispatch(updateTenant({ ...editingTenant, ...updatedValues }))
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Tenant updated',
            description: 'The tenant has been successfully updated.',
          });
          setIsModalVisible(false);
        })
        .catch((error) => {
          notification.error({
            message: 'Update failed',
            description: error.message || 'An error occurred while updating the tenant.',
          });
        });
    } else {
      dispatch(addTenant(updatedValues))
        .unwrap()
        .then(() => {
          notification.success({
            message: 'Tenant added',
            description: 'The tenant has been successfully added.',
          });
          setIsModalVisible(false);
        })
        .catch((error) => {
          notification.error({
            message: 'Add failed',
            description: error.message || 'An error occurred while adding the tenant.',
          });
        });
    }
  };

  const expandedRowRender = (record: TenantData) => {
    return (
      <div>
        <Divider />
        <div>
          {renderUploadComponent(record)}
        </div>
      </div>
    );
  };

  const columns: ColumnsType<TenantData> = [
    // ... existing columns remain the same ...
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      filters: Array.from(new Set(tenants.map(t => t.full_name))).map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.full_name.indexOf(value as string) === 0,
      render: (full_name: string) => (
        <Space>
          <span style={{ fontWeight: 'bold' }}>{full_name}</span>
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filters: Array.from(new Set(tenants.map(t => t.email))).map(email => ({ text: email, value: email })),
      onFilter: (value, record) => record.email.indexOf(value as string) === 0,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    {
      title: 'Date of Birth',
      dataIndex: 'date_of_birth',
      key: 'date_of_birth',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
      sorter: (a, b) => dayjs(a.date_of_birth).unix() - dayjs(b.date_of_birth).unix(),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      filters: Array.from(new Set(tenants.map(t => t.address))).map(address => ({ text: address, value: address })),
      onFilter: (value, record) => record.address.indexOf(value as string) === 0,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined style={{ color: 'black' }} />}
            size="small"
            type='link'
            onClick={() => handleEdit(record)}
          />
          <Button
            icon={<DeleteOutlined style={{ color: 'black' }} />}
            size="small"
            type='link'
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    return () => {
      Object.values(imageUrls).forEach(urls => {
        urls.forEach(url => URL.revokeObjectURL(url));
      });
    };
  }, [imageUrls]);

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <h1>Tenants</h1>
      <Table 
        columns={columns} 
        dataSource={tenants} 
        rowKey="id" 
        style={{ width: '100%' }}
        expandable={{ expandedRowRender }}
      />

      <Modal
        title={editingTenant ? 'Edit Tenant' : 'Add Tenant'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          initialValues={editingTenant || {}}
          onFinish={handleModalOk}
          layout="vertical"
        >
          <Form.Item
            label="Full Name"
            name="full_name"
            rules={[{ required: true, message: 'Please input the full name!' }]}
            style={{ width: '50%' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please input the email!' }]}
            style={{ width: '50%' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone_number"
            rules={[
              { required: true, message: 'Please enter the phone number' },
              { pattern: phoneNumberPattern, message: 'Please enter a valid phone number' }
            ]}            
            style={{ width: '50%' }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="date_of_birth"
            rules={[{ required: true, message: 'Please input the date of birth!' }]}
            style={{ width: '50%' }}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: 'Please input the address!' }]}
            style={{ width: '50%' }}
          >
            <Input />
          </Form.Item>
          {editingTenant && (
            <Form.Item label="Upload Documents">
              {renderUploadComponent(editingTenant)}
            </Form.Item>
          )}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingTenant ? 'Save Changes' : 'Add Tenant'}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Space>
  );
};

export default Tenants;