import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, Select, Button, Progress, Slider, InputNumber, message, DatePicker } from 'antd';
import { addProperty, PropertyData } from '../../redux/propertiesSlice';
import { PropertyType, PropertyStatus  } from '../../utils/options'; // Import the constant
import { RootState } from '../../redux/store';


const { Option } = Select;

interface AddPropertyWizardProps {
    visible: boolean;
    onCancel: () => void;
}

const AddPropertyWizard: React.FC<AddPropertyWizardProps> = ({ visible, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formValues, setFormValues] = useState<any>({});
    const [sizeValue, setSizeValue] = useState<number>(0);
    const [purchaseValue, setPurchaseValue] = useState<number>(0);
    const [estimatedValue, setEstimatedValue] = useState<number>(0);
    const [purchaseDate, setPurchaseDate] = useState<any>(null);
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.user.data);
    const dispatch = useDispatch();

    const steps = [
        {
            title: 'Property Name',
            content: (
                <Form.Item
                    name="property_name"
                    label="Property Name"
                    rules={[{ required: true, message: 'Please enter the property name' }]}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'Full Address',
            content: (
                <Form.Item
                    name="full_address"
                    label="Full Address"
                    rules={[{ required: true, message: 'Please enter the full address' }]}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'Size',
            content: (
                <Form.Item label="Size (sq ft)" rules={[{ required: true, message: 'Please enter the size' }]}>
                    <Slider
                        min={0}
                        max={1000}
                        step={5}
                        value={sizeValue}
                        onChange={(value) => setSizeValue(value)}
                        style={{ width: '50%' }}
                    />
                    <InputNumber
                        min={0}
                        max={10000}
                        value={sizeValue}
                        onChange={(value) => setSizeValue(value === null ? 0 : value)}
                        style={{ width: '50%', marginTop: '10px' }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Type',
            content: (
                <Form.Item
                    name="type"
                    label="Type"
                    rules={[{ required: true, message: 'Please select a property type' }]}
                >
                    <Select>
                        {Object.entries(PropertyType).map(([label, value]) => (
                            <Option key={value} value={value}>
                                {value.replace('_', ' ')}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
        },
        {
            title: 'Status',
            content: (
                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{ required: true, message: 'Please select the status' }]}
                >
                    <Select>
                        {Object.entries(PropertyStatus).map(([label, value]) => (
                            <Option key={value} value={value}>
                                {value.replace('_', ' ')}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
        },
        {
            title: 'Purchase Cost',
            content: (
                <Form.Item label="Purchase Cost ($)" rules={[{ required: true, message: 'Please enter the cost' }]}>
                    <Slider
                        min={0}
                        max={10000000}
                        step={200}
                        value={purchaseValue}
                        onChange={(value) => setPurchaseValue(value)}
                        style={{ width: '50%' }}
                    />
                    <InputNumber
                        min={0}
                        max={10000000}
                        value={purchaseValue}
                        onChange={(value) => setPurchaseValue(value === null ? 0 : value)}
                        style={{ width: '50%', marginTop: '10px' }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Current Estimated Cost',
            content: (
                <Form.Item label="Estimated cost ($)" rules={[{ required: true, message: 'Please enter the cost' }]}>
                    <Slider
                        min={0}
                        max={10000000}
                        step={200}
                        value={estimatedValue}
                        onChange={(value) => setEstimatedValue(value)}
                        style={{ width: '50%' }}
                    />
                    <InputNumber
                        min={0}
                        max={10000000}
                        value={estimatedValue}
                        onChange={(value) => setEstimatedValue(value === null ? 0 : value)}
                        style={{ width: '50%', marginTop: '10px' }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Purchase Date',
            content: (
                <Form.Item
                    name="purchase_date"
                    label="Purchase Date"
                    rules={[{ required: true, message: 'Please select the purchase date' }]}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                        onChange={(date) => setPurchaseDate(date)}
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            ),
        },
    ];

    const handleAddProperty = async (values: any) => {
        try {
            const propertyData: PropertyData = {
                id: -1,
                property_name: values.property_name,
                full_address: values.full_address,
                size: sizeValue,
                type: values.type,
                status: values.status,
                purchase_cost: purchaseValue,
                purchase_date: purchaseDate ? purchaseDate.format('YYYY-MM-DD') : null,
                estimated_cost: estimatedValue,
                team_id: user?.team_id
            };
            await dispatch(addProperty(propertyData) as any);
            message.success('Property created successfully');
            onCancel();
            form.resetFields();
            setCurrentStep(0);
        } catch (error) {
            console.error('Error adding property:', error);
            message.error('Failed to create property');
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = (values: any) => {
        setFormValues({ ...formValues, ...values });
        if (currentStep === steps.length - 1) {
            handleAddProperty({ ...formValues, ...values });
        } else {
            nextStep();
        }
    };

    const handleCancel = () => {
        onCancel();
        form.resetFields();
        resetFormState();
        setCurrentStep(0);
    };

    const resetFormState = () => {
        setSizeValue(0);
        setPurchaseValue(0);
        setEstimatedValue(0);
        setPurchaseDate(null);
    };

    return (
        <Modal
            title="Add New Property"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            centered
        >
            <Progress
                percent={(currentStep / (steps.length - 1)) * 100}
                showInfo={false}
                style={{ marginBottom: '24px' }}
            />

            <Form layout="vertical" onFinish={handleFinish} form={form}>
                {steps[currentStep].content}
                <div style={{ marginTop: 24 }}>
                    {currentStep > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={prevStep}>
                            Previous
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddPropertyWizard;
