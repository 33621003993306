import React, { useEffect, useState } from 'react';
import { Card, List, Typography, notification, Image, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchProperties, PropertyData } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';
import { fetchPropertyFiles } from '../redux/filesSlice';
import { RootState, AppDispatch } from '../redux/store';
import { HomeOutlined, TagOutlined } from '@ant-design/icons';
import { formatCurrency } from '../utils/currencyFormatter';
import dayjs from 'dayjs';
import { readAssetRaw } from '@directus/sdk';
import directus from '../utils/directusClient';

const { Text } = Typography;

const PropertyCard: React.FC<{ 
  property: PropertyData; 
  onClick: () => void;
  imageUrl: string;
}> = ({ property, onClick, imageUrl }) => (
  <Card
    hoverable
    bodyStyle={{ padding: '16px', height: '180px' }}
    style={{ 
      width: '100%', 
      marginBottom: 16,
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
      overflow: 'hidden'
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = 'translateY(-2px)';
      e.currentTarget.style.boxShadow = '0 4px 12px rgba(0,0,0,0.12)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = 'none';
      e.currentTarget.style.boxShadow = '0 2px 8px rgba(0,0,0,0.08)';
    }}
    onClick={onClick}
  >
    <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <div style={{ 
        flexShrink: 0, 
        marginRight: 20, 
        width: 140, 
        height: 140,
        borderRadius: '8px',
        overflow: 'hidden',
        background: '#f0f2f5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center' 
      }}>
        {imageUrl ? (
          <Image 
            src={imageUrl} 
            alt="Property" 
            width={140} 
            height={140}
            style={{ objectFit: 'cover' }}
            preview={false}
          />
        ) : (
          <HomeOutlined style={{ fontSize: 50, color: '#1890ff' }} />
        )}
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Text strong style={{ fontSize: '18px', color: '#1a1a1a' }}>{property.property_name}</Text>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TagOutlined style={{ fontSize: '12px', color: '#8c8c8c', marginRight: 4 }} />
            <Text type="secondary" style={{ fontSize: '13px' }}>{property.full_address}</Text>
          </div>
        </div>
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(3, 1fr)', 
          gap: '8px',
          fontSize: '12px',
          marginTop: '4px' 
        }}>
          <PropertyStat label="Size" value={`${property.size} sq ft`} />
          <PropertyStat label="Type" value={property.type} />
          <PropertyStat label="Status" value={property.status} />
        </div>
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: 'repeat(3, 1fr)', 
          gap: '12px',
          fontSize: '13px',
          marginTop: '8px' 
        }}>
          <PropertyStat label="Purchase" value={formatCurrency(property.purchase_cost)} />
          <PropertyStat label="Date" value={dayjs(property.purchase_date).format('YYYY-MM-DD')} />
          <PropertyStat label="Est. Cost" value={formatCurrency(property.estimated_cost)} />
        </div>
      </div>
    </div>
  </Card>
);

const PropertyStat: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div>
    <Text type="secondary" style={{ display: 'block', marginBottom: 2 }}>{label}</Text>
    <Text strong>{value}</Text>
  </div>
);

const Properties: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const properties = useSelector((state: RootState) => state?.properties.data);
  const files = useSelector((state: RootState) => state.files.files);
  const error = useSelector((state: RootState) => state.properties.error);
  const propertiesLoading = useSelector((state: RootState) => state.properties.status === 'loading');
  const filesLoading = useSelector((state: RootState) => state.files.fetchStatus === 'loading');  
  const [imageUrls, setImageUrls] = useState<{ [key: number]: string }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [fetchedImages, setFetchedImages] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = window.innerWidth < 2048 ? 6 : 10; // Show 5 items per page on laptops, 10 items per page on larger screens

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([
        dispatch(fetchProperties()),
        dispatch(fetchPropertyFiles()),
        dispatch(fetchLeasings()),
        dispatch(fetchTenants()),
        dispatch(fetchExpenses())
      ]);
    };
    fetchAllData();
  }, [dispatch]);

  useEffect(() => {
    if (error) {  
      notification.error({
        message: 'Error',
        description: error,
      });
    }
  }, [error]);

  useEffect(() => {
    const fetchImages = async () => {
      if (fetchedImages || propertiesLoading || filesLoading) {
        return;
      }

      if (!properties.length || !files.length) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const newImageUrls: { [key: number]: string } = {};

      try {
        for (const property of properties) {
          const propertyFiles = files.filter(file => 
            file.entity_id === property.id && file.entity_type === 'property'
          );
          
          if (propertyFiles.length > 0) {
            const response = await directus.request(readAssetRaw(propertyFiles[0].id));
            const reader = response.getReader();
            const chunks = [];

            while (true) {
              const { value, done } = await reader.read();
              if (done) break;
              if (value) chunks.push(value);
            }

            const blob = new Blob(chunks, { type: 'image/jpeg' });
            newImageUrls[property.id] = URL.createObjectURL(blob);
          }
        }

        setImageUrls(prevUrls => ({
          ...prevUrls,
          ...newImageUrls
        }));
        setFetchedImages(true);
      } catch (error) {
        console.error('Error fetching images:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();

    return () => {
      if (!fetchedImages) {
        Object.values(imageUrls).forEach(url => URL.revokeObjectURL(url));
      }
    };
  }, [properties, files, propertiesLoading, filesLoading, fetchedImages]);

  const handlePropertyClick = (property: PropertyData) => {
    navigate(`/properties/${property.id}`);
  };

  return (
    <div>
      <h2>Properties</h2>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
          <Spin size="large" tip="Loading properties..." />
        </div>
      ) : properties.length === 0 ? (
        <div style={{ textAlign: 'center', padding: '50px' }}>
          <HomeOutlined style={{ fontSize: 24, color: '#1890ff', marginBottom: 16 }} />
          <br />
          <Text type="secondary">No Data</Text>
        </div>
      ) : (
        <List
          grid={{ gutter: 16, column: 2 }}
          dataSource={properties}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: properties.length,
            onChange: (page) => setCurrentPage(page),
            style: { textAlign: 'center', marginTop: '16px' }
          }}
          renderItem={(property) => (
            <List.Item key={property.id} style={{ padding: 0 }}>
              <PropertyCard
                property={property}
                onClick={() => handlePropertyClick(property)}
                imageUrl={imageUrls[property.id]}
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default Properties;