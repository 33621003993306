import { createDirectus, authentication, rest } from '@directus/sdk';

// Get the Directus API URL from environment variables
const directusUrl = process.env.REACT_APP_ENV === 'prod' 
  ? "https://app.blurben.com:8055" 
  : 'http://localhost:8055';
  
// Singleton Directus client instance
// const directus = createDirectus(directusUrl).with(authentication('cookie')).with(rest());

const directus = createDirectus(directusUrl)
.with(rest({credentials: "include"}))
.with(authentication('session', { 
    credentials: 'include',
    autoRefresh: true 
}));

// Use saved token from localStorage if available
// const savedToken = localStorage.getItem('authToken');
// if (savedToken) {
//   directus.setToken(savedToken);
// }

export default directus;
