import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Carousel } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { 
  Typography, Descriptions, Tabs, Table, Button, Space, 
  Form, Input, InputNumber, DatePicker, Select, Popconfirm, notification, Row, Col, Upload, Modal, Spin
} from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, CloseOutlined, ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { RootState, AppDispatch } from '../redux/store';
import { updateProperty, deleteProperty, PropertyData } from '../redux/propertiesSlice';
import { fetchPropertyFiles, uploadPropertyFile, deletePropertyFile } from '../redux/filesSlice';
import { ExpenseData } from '../redux/expensesSlice';
import { LeasingData } from '../redux/leasingsSlice';
import { TenantData } from '../redux/tenantsSlice';
import { formatCurrency } from '../utils/currencyFormatter';
import { PropertyStatus, PropertyType } from '../utils/options';
import { readAssetRaw } from '@directus/sdk';
import directus from '../utils/directusClient';

import dayjs from 'dayjs';

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const PropertyDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  const [isLoadingImages, setIsLoadingImages] = useState(true);

  const property = useSelector((state: RootState) => 
    state.properties.data.find(p => p.id === Number(id))
  );
  const expenses = useSelector((state: RootState) => 
    state.expenses.data.filter(expense => expense.property_id.id === Number(id))
  );
  const leasings = useSelector((state: RootState) => 
    state.leasings.data.filter(leasing => leasing.property_id.id === Number(id))
  );
  const tenants = useSelector((state: RootState) => state.tenants.data);
  const files = useSelector((state: RootState) => 
    state.files.files.filter(file => file.entity_id == Number(id) && file.entity_type == 'property')
  );

  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  const filesRef = useRef(files);

  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    if (property) {
      form.setFieldsValue({
        ...property,
        purchase_date: dayjs(property.purchase_date)
      });
    }
  }, [property, form]);

  const fetchImages = useCallback(async () => {
    setIsLoadingImages(true);
    const urls: string[] = [];

    for (const file of filesRef.current) {
      try {
        const response = await directus.request(readAssetRaw(file.id));
        const reader = response.getReader();
        const chunks: Uint8Array[] = [];

        let done = false;
        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;
          if (value) {
            chunks.push(value);
          }
        }

        const blob = new Blob(chunks, { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        urls.push(url);
      } catch (error) {
        console.error(`Error fetching image for file ${file.id}:`, error);
      }
    }

    setImageUrls(urls);
    setIsLoadingImages(false);
  }, []);

  useEffect(() => {
    if (imageUrls.length == 0 || imageUrls.length != files.length) {
      filesRef.current = files;
      if (files.length > 0) {
        fetchImages();
      }
    }
  }, [files]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const showDeleteConfirm = (file: any) => {
    setFileToDelete(file);
    setIsDeleteModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    if (fileToDelete) {
      try {
        await dispatch(deletePropertyFile(fileToDelete.uid));
        notification.success({ message: 'Photo deleted successfully' });
        dispatch(fetchPropertyFiles());
      } catch (error: any) {
        notification.error({ message: 'Failed to delete photo', description: error.message });
      } finally {
        setIsDeleteModalVisible(false);
        setFileToDelete(null);
      }
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await dispatch(updateProperty({ ...property, ...values }));
      setIsEditing(false);
      notification.success({ message: 'Property updated successfully' });
    } catch (error: any) {
      notification.error({ message: 'Failed to update property', description: error.message });
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteProperty(property!.id)).unwrap();
      notification.success({ message: 'Property deleted successfully' });
      navigate('/properties');
    } catch (error: any) {
      notification.error({ message: 'Failed to delete property', description: error.message });
    }
  };

  const handleGoBack = () => {
    navigate('/properties');
  };

  const handlePhotoUpload = async ({ file }: any) => {
    try {
      await dispatch(uploadPropertyFile({ file, entityId: property!.id, entityType: "property", teamId: property!.team_id || 0, folderName: 'properties' }));
      notification.success({ message: 'Photo uploaded successfully' });
      dispatch(fetchPropertyFiles());
    } catch (error: any) {
      notification.error({ message: 'Failed to upload photo', description: error.message });
    }
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleRemove = async (file: any) => {
    try {
      await dispatch(deletePropertyFile(file.uid));
      notification.success({ message: 'Photo deleted successfully' });
      dispatch(fetchPropertyFiles());
    } catch (error: any) {
      notification.error({ message: 'Failed to delete photo', description: error.message });
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload image</div>
    </div>
  );

  const expenseColumns = [
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (amount: number) => formatCurrency(amount) },
    { title: 'Date', dataIndex: 'date', key: 'date', render: (date: string) => dayjs(date).format('YYYY-MM-DD') },
    { title: 'Description', dataIndex: 'description', key: 'description' },
  ];

  const leasingColumns = [
    { title: 'Tenant', dataIndex: ['tenant_id', 'full_name'], key: 'tenant' },
    { title: 'Start Date', dataIndex: 'lease_start_date', key: 'startDate', render: (date: string) => dayjs(date).format('YYYY-MM-DD') },
    { title: 'End Date', dataIndex: 'lease_end_date', key: 'endDate', render: (date: string) => dayjs(date).format('YYYY-MM-DD') },
    { title: 'Monthly Rent', dataIndex: 'monthly_rent', key: 'rent', render: (amount: number) => formatCurrency(amount) },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  ];

  const currentTenants = leasings
    .filter(leasing => leasing.status === 'signed')
    .map(leasing => tenants.find(tenant => tenant.id === leasing.tenant_id.id))
    .filter((tenant): tenant is TenantData => tenant !== undefined);

  if (!property) {
    return <div>Property not found</div>;
  }

  return (
    <div>
      <Space style={{ marginBottom: 16, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button icon={<ArrowLeftOutlined />} onClick={handleGoBack} type="link">Back</Button>
        <Title level={2} style={{ margin: 0 }}>{property.property_name}</Title>
        <div style={{ width: '24px' }}></div> {/* Spacer to balance the back button */}
      </Space>

      <Form form={form} layout="vertical">
        <Tabs defaultActiveKey="1" onChange={setActiveTab}>
          <TabPane tab="Property Details" key="1">
            {activeTab === '1' && (
              <>
                <Space style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
                  {!isEditing && (
                    <>
                      <Button icon={<EditOutlined />} onClick={handleEdit}>Edit</Button>
                      <Popconfirm
                        title="Are you sure you want to delete this property?"
                        onConfirm={handleDelete}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button icon={<DeleteOutlined />} danger>Delete</Button>
                      </Popconfirm>
                    </>
                  )}
                  {isEditing && (
                    <>
                      <Button icon={<SaveOutlined />} onClick={handleSave} type="primary">Save</Button>
                      <Button icon={<CloseOutlined />} onClick={() => setIsEditing(false)}>Cancel</Button>
                    </>
                  )}
                </Space>
                <Row gutter={24}>
                  <Col span={12}>
                    {isLoadingImages && files.length > 0 ? (
                      <div style={{ textAlign: 'center', padding: '40px 0' }}>
                        <Spin tip="Loading images..." />
                      </div>
                    ) : (
                      <Carousel dotPosition="bottom" arrows>
                        {imageUrls.map((url, index) => (
                          <div key={index}>
                            <img alt={`Property ${index}`} style={{ width: '100%', height: 'auto' }} src={url} />
                          </div>
                        ))}
                      </Carousel>
                    )}
                    <Upload
                      listType="picture-card"
                      fileList={files.map((file, index) => ({
                        uid: file.id,
                        name: file.filename_download,
                        status: 'done',
                        url: imageUrls[index],
                      }))}
                      onPreview={handlePreview}
                      customRequest={handlePhotoUpload}
                      onRemove={file => showDeleteConfirm(file)}
                    >
                      {files.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      title="Confirm Delete"
                      visible={isDeleteModalVisible}
                      onOk={handleConfirmDelete}
                      onCancel={() => setIsDeleteModalVisible(false)}
                      okText="Delete"
                      cancelText="Cancel"
                      centered
                    >
                      <p>Are you sure you want to delete this photo?</p>
                    </Modal>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancelPreview}
                      centered
                    >
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </Col>
                  <Col span={12}>
                    <Descriptions bordered column={1}>
                      <Descriptions.Item label="Property Name">
                        {isEditing ? (
                          <Form.Item 
                            name="property_name" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <Input />
                          </Form.Item>
                        ) : (
                          property.property_name
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Address">
                        {isEditing ? (
                          <Form.Item 
                            name="full_address" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <Input />
                          </Form.Item>
                        ) : (
                          property.full_address
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Size">
                        {isEditing ? (
                          <Form.Item 
                            name="size" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <InputNumber addonAfter="sq ft" />
                          </Form.Item>
                        ) : (
                          `${property.size} sq ft`
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Type">
                        {isEditing ? (
                          <Form.Item 
                            name="type" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <Select>
                              {Object.entries(PropertyType).map(([label, value]) => (
                                <Option key={value} value={value}>
                                  {value.replace('_', ' ')}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          property.type
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Status">
                        {isEditing ? (
                          <Form.Item 
                            name="status" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <Select>
                              {Object.entries(PropertyStatus).map(([label, value]) => (
                                <Option key={value} value={value}>
                                  {value.replace('_', ' ')}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        ) : (
                          property.status
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Purchase Cost">
                        {isEditing ? (
                          <Form.Item 
                            name="purchase_cost" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <InputNumber
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        ) : (
                          formatCurrency(property.purchase_cost)
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Purchase Date">
                        {isEditing ? (
                          <Form.Item 
                            name="purchase_date" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <DatePicker />
                          </Form.Item>
                        ) : (
                          dayjs(property.purchase_date).format('YYYY-MM-DD')
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Estimated Cost">
                        {isEditing ? (
                          <Form.Item 
                            name="estimated_cost" 
                            rules={[{ required: true }]}
                            style={{ margin: 0 }}
                          >
                            <InputNumber
                              formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        ) : (
                          formatCurrency(property.estimated_cost)
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </>
            )}
          </TabPane>
          <TabPane tab="Current Tenants" key="2">
            <Table dataSource={currentTenants} columns={[
              { title: 'Full Name', dataIndex: 'full_name', key: 'name' },
              { title: 'Email', dataIndex: 'email', key: 'email' },
              { title: 'Phone', dataIndex: 'phone_number', key: 'phone' },
            ]} rowKey="id" />
          </TabPane>
          <TabPane tab="Leasing History" key="3">
            <Table dataSource={leasings} columns={leasingColumns} rowKey="id" />
          </TabPane>
          <TabPane tab="Expenses" key="4">
            <Table dataSource={expenses} columns={expenseColumns} rowKey="id" />
          </TabPane>
        </Tabs>
      </Form>
    </div>
  );
};

export default PropertyDetailsPage;