import React, { useState } from 'react';
import {
    HomeOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    FileDoneOutlined,
    TeamOutlined,
    CreditCardOutlined,
    CalendarOutlined,
    PieChartOutlined,
    StarOutlined, // Import the Star icon for the Pro label
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Layout, Menu, Tooltip } from 'antd';

const { Sider } = Layout;

interface PageHeaderProps {
    collapsed: boolean;
    handleCollapse: () => void;
}

const SideBar = ({ collapsed, handleCollapse }: PageHeaderProps) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);
    const location = useLocation();
    const navigate = useNavigate();

    // Update the selected key based on the current path
    const currentPath = location.pathname.split('/')[1];
    const selectedKey = navMenuItems.find(item => item.key.toLowerCase() === currentPath.toLowerCase())?.key || '';

    const handleCollapseChange = () => {
        setIsCollapsed(!isCollapsed);
        handleCollapse();
    };

    return (
        <Sider
            breakpoint="lg"
            collapsed={isCollapsed}
            trigger={null}
            collapsedWidth="50px"
            collapsible
            className="sidebar"
            style={{
                overflow: 'hidden',
                paddingTop: '20px',
                color: 'white',
                backgroundColor: '#1C4E80',
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                height: '100vh',
                justifyContent: 'space-between',
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div
                    style={{
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                        fontSize: '12px',
                        cursor: 'pointer',
                        width: '100%',
                        padding: '0 10px',
                    }}
                    onClick={handleCollapseChange}
                >
                    <Tooltip title={isCollapsed ? 'Expand' : 'Collapse'}>
                        {isCollapsed ? (
                            <MenuUnfoldOutlined style={{ fontSize: '18px', color: 'white' }} />
                        ) : (
                            <MenuFoldOutlined style={{ fontSize: '18px', color: 'white' }} />
                        )}
                    </Tooltip>
                </div>
                <Divider
                    style={{
                        backgroundColor: '#ffffff21',
                        margin: '15px 0 5px',
                    }}
                />
                <Menu
                    style={{ backgroundColor: 'inherit', color: 'white' }}
                    mode="inline"
                    selectedKeys={[selectedKey]} // Set the selected key
                    onClick={({ key }) => navigate(`/${key}`)}
                    defaultOpenKeys={!isCollapsed ? ['report-builder'] : []}
                    triggerSubMenuAction="click"
                    items={navMenuItems.map((item) => {
                        return {
                            key: item.key,
                            icon: <span style={{ color: 'white' }}>{item.icon}</span>,
                            label: (
                                <span style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
                                    {item.label}
                                    {item.isPro && (
                                        <span style={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
                                            <StarOutlined style={{ color: 'gold', fontSize: '12px' }} />
                                            {/* <span style={{ color: 'gold', fontSize: '12px', marginLeft: '0px' }}>Pro</span> */}
                                        </span>
                                    )}
                                </span>
                            ),
                            disabled: item.disabled,
                        };
                    })}
                />
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '10px',
                    textAlign: 'center',
                    backgroundColor: '#1C4E80',
                }}
            >
                <HomeOutlined style={{ fontSize: '35px', color: '#ffffff', marginRight: isCollapsed ? '0' : '10px' }} />
                {!isCollapsed && (
                    <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#ffffff' }}>Blurben</span>
                )}
            </div>
        </Sider>
    );
};

const navMenuItems = [
    {
        key: 'Dashboard',
        icon: <PieChartOutlined />,
        label: 'Dashboard',
        disabled: false,
    },
    {
        key: 'Properties',
        icon: <HomeOutlined />,
        label: 'Properties',
        disabled: false,
    },
    {
        key: 'Leases',
        icon: <FileDoneOutlined />,
        label: 'Leases',
        disabled: false,
    },
    {
        key: 'Tenants',
        icon: <TeamOutlined />,
        label: 'Tenants',
        disabled: false,
    },
    {
        key: 'Expenses',
        icon: <CreditCardOutlined />,
        label: 'Expenses',
        disabled: false,
    },
    {
        key: 'Calendar',
        icon: <CalendarOutlined />,
        label: 'Calendar',
        disabled: false,
    },
    {
        key: 'Overview',
        icon: <CalendarOutlined />,
        label: 'Overview',
        disabled: false,
        isPro: true, // Mark Overview as a Pro feature
    },
    {
        key: 'Reports',
        icon: <FileDoneOutlined />,
        label: 'Reports',
        disabled: false,
        isPro: true, // Making it a Pro feature
    },
];

export default SideBar;
