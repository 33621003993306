import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { updateItem, readMe, createItem } from '@directus/sdk';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';
import { AppDispatch } from '../redux/store';
import { createDirectus, authentication, rest } from '@directus/sdk';
import { UserData } from '../redux/userSlice';

const directusUrl = process.env.REACT_APP_ENV === 'prod' 
  ? "https://app.blurben.com:8055" 
  : 'http://localhost:8055';

const AuthCallback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const hasAuthenticated = useRef(false);

  useEffect(() => {
    if (hasAuthenticated.current) return;
    hasAuthenticated.current = true;

    const authenticate = async () => {
      try {
        const client = createDirectus(directusUrl)
            .with(rest({credentials: "include"}))
            .with(authentication('session', { 
                credentials: 'include',
                autoRefresh: true 
            }));

        // Get current user data
        const userData = await client.request<UserData>(readMe({
            fields: ['*'] // Include all leasing fields and property data
        }));
        
        // Check if user has a team_id
        if (!userData.team_id) {
          interface Team {
            id: string;
            name: string;
          }
          // Create new team with proper typing
          const newTeam = await client.request<Team>(createItem('teams', {
            name: `${userData.first_name}'s Team`
          }));

          // Now we can safely use newTeam.id
          await client.request(updateItem('teams', newTeam.id, {
            directus_users: [userData.id]
          }));

          // Refresh user data to get updated team info
          const updatedUserData = await client.request<UserData>(readMe({
            fields: ['*']
          }));
        }

        // message.success('Logged in successfully');
          
          // Dispatch global data (if needed)
        dispatch(fetchProperties());
        dispatch(fetchLeasings());
        dispatch(fetchTenants());
        dispatch(fetchExpenses());
        
        navigate('/dashboard');

      } catch (error) {
        message.error('Something went wrong');
        navigate('/login');
      }
    };

    authenticate();
  }, [navigate, dispatch]);

  return null;
};

export default AuthCallback; 