// UnauthenticatedLayout.js
import React,{ ReactNode } from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

interface LayoutProps {
  children: ReactNode;
}

const UnauthenticatedLayout = ({ children }: LayoutProps) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '16px' }}>
        {children}
      </Content>
    </Layout>
  );
};

export default UnauthenticatedLayout;
