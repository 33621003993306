import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, DatePicker, Button, Progress, message } from 'antd';
import { addTenant, TenantData } from '../../redux/tenantsSlice'; // Adjust path as necessary
import { RootState } from '../../redux/store';

interface AddTenantWizardProps {
    visible: boolean;
    onCancel: () => void;
}

const phoneNumberPattern = /^(?:\+\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const AddTenantWizard: React.FC<AddTenantWizardProps> = ({ visible, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formValues, setFormValues] = useState<any>({});
    const [form] = Form.useForm();
    const user = useSelector((state: RootState) => state.user.data);
    const dispatch = useDispatch();


    const steps = [
        {
            title: 'Full Name',
            content: (
                <Form.Item
                    name="full_name"
                    label="Full Name"
                    rules={[{ required: true, message: 'Please enter the full name' }]}
                >
                    <Input />
                </Form.Item>
            )
        },
        {
            title: 'Contact Information',
            content: (
                <>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[{ required: true, message: 'Please enter the email' }, { type: 'email', message: 'Please enter a valid email' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone_number"
                        label="Phone Number"
                        rules={[
                            { required: true, message: 'Please enter the phone number' },
                            { pattern: phoneNumberPattern, message: 'Please enter a valid phone number' }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </>
            )
        },
        {
            title: 'Date of Birth',
            content: (
                <Form.Item
                    name="date_of_birth"
                    label="Date of Birth"
                    rules={[{ required: true, message: 'Please select the date of birth' }]}
                >
                    <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
            )
        },
        {
            title: 'Address',
            content: (
                <Form.Item
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: 'Please enter the address' }]}
                >
                    <Input />
                </Form.Item>
            )
        }
    ];

    const handleAddTenant = async (values: any) => {
        try {
            const tenantData: TenantData = {
                id: -1, // Assuming a new tenant record starts with an id of -1
                full_name: values.full_name,
                email: values.email,
                phone_number: values.phone_number,
                date_of_birth: values.date_of_birth ? values.date_of_birth.format('YYYY-MM-DD') : '',
                address: values.address,
                team_id: user?.team_id
            };

            await dispatch(addTenant(tenantData) as any);
            message.success("Tenant created successfully");
            onCancel(); // Close the modal on success
            form.resetFields(); // Reset form fields after submission
            setCurrentStep(0); // Reset the step to the beginning
        } catch (error) {
            console.error('Error adding tenant:', error);
            message.error("Failed to create tenant record");
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = (values: any) => {
        setFormValues({ ...formValues, ...values });
        if (currentStep === steps.length - 1) {
            handleAddTenant({ ...formValues, ...values });
        } else {
            nextStep();
        }
    };

    const handleCancel = () => {
        onCancel(); // Close the modal
        form.resetFields(); // Reset form fields after cancel
        setCurrentStep(0); // Reset the step to the beginning
    };

    return (
        <Modal
            title="Add New Tenant"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            centered
        >
            <Progress
                percent={(currentStep / (steps.length - 1)) * 100}
                showInfo={false}
                style={{ marginBottom: '24px' }}
            />

            <Form layout="vertical" onFinish={handleFinish} form={form}>
                {steps[currentStep].content}
                <div style={{ marginTop: 24 }}>
                    {currentStep > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={prevStep}>
                            Previous
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddTenantWizard;
