import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Input, DatePicker, InputNumber, Button, Progress, message, Select } from 'antd';
import { addLeasing, LeasingData } from '../../redux/leasingsSlice'; // Adjust path as necessary
import { RootState } from '../../redux/store'; // Adjust path as necessary
import { LeasingStatus } from '../../utils/options';

const { RangePicker } = DatePicker;

interface AddLeasingWizardProps {
    visible: boolean;
    onCancel: () => void;
}

const AddLeasingWizard: React.FC<AddLeasingWizardProps> = ({ visible, onCancel }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formValues, setFormValues] = useState<any>({});
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.data);
    const properties = useSelector((state: RootState) => state.properties.data); // Adjust as necessary
    const tenants = useSelector((state: RootState) => state.tenants.data); // Adjust as necessary

    const steps = [
        {
            title: 'Tenant Name',
            content: (
                <Form.Item
                    name="tenant_id"
                    label="Tenant Name"
                    rules={[{ required: true, message: 'Please enter the tenant name' }]}
                >
               <Select placeholder="Select a tenant">
                        {tenants.map(tenant => (
                            <Select.Option key={tenant.id} value={tenant.id}>
                                {tenant.full_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: 'Property',
            content: (
                <Form.Item
                    name="property_id"
                    label="Property"
                    rules={[{ required: true, message: 'Please select a property' }]}
                >
                    <Select placeholder="Select a property">
                        {properties.map(property => (
                            <Select.Option key={property.id} value={property.id}>
                                {property.property_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            title: 'Lease Dates',
            content: (
                <Form.Item
                    name="lease_dates"
                    label="Lease Start and End Dates"
                    rules={[{ required: true, message: 'Please select the lease dates' }]}
                >
                    <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                </Form.Item>
            )
        },
        {
            title: 'Monthly Rent',
            content: (
                <Form.Item
                    name="monthly_rent"
                    label="Monthly Rent ($)"
                    rules={[{ required: true, message: 'Please enter the monthly rent' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
            )
        }
    ];

    const handleAddLeasing = async (values: any) => {
        try {
            const leaseStartDate = values.lease_dates ? values.lease_dates[0].format('YYYY-MM-DD') : '';
            const leaseEndDate = values.lease_dates ? values.lease_dates[1].format('YYYY-MM-DD') : '';

            const leasingData: LeasingData = {
                id: -1, // Assuming a new leasing record starts with an id of -1
                // tenant_name: values.tenant_name,
                property_id: {
                    id: values.property_id,
                    property_name: properties.find(p => p.id === values.property_id)?.property_name || ''
                    // property_name:"test"
                },
                tenant_id: {
                    id: values.tenant_id,
                    full_name: tenants.find(t => t.id === values.tenant_id)?.full_name || ''
                    // property_name:"test"
                },
                lease_start_date: leaseStartDate,
                lease_end_date: leaseEndDate,
                monthly_rent: values.monthly_rent,
                status: LeasingStatus.NEW,
                team_id: user?.team_id
            };

            await dispatch(addLeasing(leasingData) as any);
            message.success("Leasing created successfully");
            onCancel(); // Close the modal on success
            form.resetFields(); // Reset form fields after submission
            setCurrentStep(0); // Reset the step to the beginning
        } catch (error) {
            console.error('Error adding leasing:', error);
            message.error("Failed to create leasing record");
        }
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = (values: any) => {
        setFormValues({ ...formValues, ...values });
        if (currentStep === steps.length - 1) {
            handleAddLeasing({ ...formValues, ...values });
        } else {
            nextStep();
        }
    };

    const handleCancel = () => {
        onCancel(); // Close the modal
        form.resetFields(); // Reset form fields after cancel
        setCurrentStep(0); // Reset the step to the beginning
    };

    return (
        <Modal
            title="Add New Leasing"
            visible={visible}
            onCancel={handleCancel}
            footer={null}
            centered
        >
            <Progress
                percent={(currentStep / (steps.length - 1)) * 100}
                showInfo={false}
                style={{ marginBottom: '24px' }}
            />

            <Form layout="vertical" onFinish={handleFinish} form={form}>
                {steps[currentStep].content}
                <div style={{ marginTop: 24 }}>
                    {currentStep > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={prevStep}>
                            Previous
                        </Button>
                    )}
                    <Button type="primary" htmlType="submit">
                        {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default AddLeasingWizard;