import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import directus from '../utils/directusClient';
import { readItem, readMe } from '@directus/sdk';

export interface UserData {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    // team_id: {
    //   id: number;
    //   name: string;
    // } ,
    team_id: number;
  }

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  // const response = await directus.request<UserData>(readMe({
  //     fields: ['*', 'team_id.*'] // Include all leasing fields and property data
  // }));
    const response = await directus.request<UserData>(readMe());
    return response; // Assuming Directus returns the user data directly under 'data'
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
      data: null as UserData | null, // Initial state to hold user data
      status: 'idle' as 'idle' | 'loading' | 'succeeded' | 'failed',
      error: null as string | null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchUser.pending, (state) => {
          state.status = 'loading';
          state.error = null;
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.data = action.payload;
          state.error = null;
        })
        .addCase(fetchUser.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to fetch user data.';
        });
    },
  });
  
  export default userSlice.reducer;