import React, { useState, useEffect } from 'react';
import { Calendar as AntCalendar, Modal, Tag, Layout } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, DollarOutlined, HomeOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// Add these interfaces at the top
interface CalendarEvent {
  id: string;
  type: 'lease' | 'expense' | 'purchase';
  date: string;
  title: string;
  amount?: number;
  property_name: string;
  description?: string;
  tenant_name?: string;
  monthly_rent?: number;
  lease_start_date?: string;
  lease_end_date?: string;
}

// Function to render the content in each cell with icons and colors for lease status
const dateCellRender = (
  date: Dayjs, 
  leasings: any[], 
  expenses: any[], 
  properties: any[],
  onDateClick: (events: CalendarEvent[], date: Dayjs) => void
) => {
  // Get all events for this date
  const dailyEvents: CalendarEvent[] = [
    // Lease events
    ...leasings
      .filter(leasing => 
        dayjs(leasing.lease_start_date).isSame(date, 'day') ||
        dayjs(leasing.lease_end_date).isSame(date, 'day')
      )
      .map(leasing => ({
        id: `lease-${leasing.id}`,
        type: 'lease' as const,
        date: date.format('YYYY-MM-DD'),
        title: dayjs(leasing.lease_start_date).isSame(date, 'day') ? 'Lease Signed' : 'Lease Expiring',
        property_name: leasing.property_id.property_name,
        tenant_name: leasing.tenant_id.full_name,
        monthly_rent: leasing.monthly_rent,
        lease_start_date: leasing.lease_start_date,
        lease_end_date: leasing.lease_end_date
      })),
    // Monthly Payment events
    ...leasings.flatMap(leasing => {
      const startDate = dayjs(leasing.lease_start_date);
      const endDate = dayjs(leasing.lease_end_date);
      const currentDate = date.startOf('day');
      
      // Check if the current date is the 15th and falls within the lease period
      if (
        currentDate.date() === startDate.date() && // Check if it's the same day of the month as the start date
        currentDate.isSameOrAfter(startDate, 'month') && // Ensure it's the same or after the start month
        currentDate.isSameOrBefore(endDate, 'day') // Ensure it's before or on the end date
      ) {
        return [{
          id: `payment-${leasing.id}-${currentDate.format('YYYY-MM')}`,
          type: 'lease' as const,
          date: currentDate.format('YYYY-MM-DD'),
          title: `Payment Due ($${leasing.monthly_rent?.toLocaleString()})`,
          property_name: leasing.property_id.property_name,
          tenant_name: leasing.tenant_id.full_name,
          monthly_rent: leasing.monthly_rent,
          lease_start_date: leasing.lease_start_date,
          lease_end_date: leasing.lease_end_date
        }];
      }
      return [];
    }),
    // Expense events
    ...expenses
      .filter(expense => dayjs(expense.date).isSame(date, 'day'))
      .map(expense => ({
        id: `expense-${expense.id}`,
        type: 'expense' as const,
        date: expense.date,
        title: 'Expense',
        amount: expense.amount,
        property_name: expense.property_id.property_name,
        description: expense.description
      })),
    // Purchase events
    ...properties
      .filter(property => dayjs(property.purchase_date).isSame(date, 'day'))
      .map(property => ({
        id: `purchase-${property.id}`,
        type: 'purchase' as const,
        date: property.purchase_date,
        title: 'Property Purchased',
        amount: property.purchase_cost,
        property_name: property.property_name
      }))
  ];

  return (
    <div onClick={() => onDateClick(dailyEvents, date)} style={{ cursor: 'pointer' }}>
      {dailyEvents.map((event) => (
        <div key={event.id} style={{ marginBottom: '2px' }}>
          <Tag 
            icon={getEventIcon(event.type)} 
            color={getEventColor(event.type)}
            style={{ 
              maxWidth: '100%',
              whiteSpace: 'normal',
              height: 'auto',
              lineHeight: '1.2',
              padding: '2px 4px'
            }}
          >
            {event.property_name} - {event.title}
          </Tag>
        </div>
      ))}
    </div>
  );
};

// Add these helper functions
const getEventIcon = (type: string) => {
  switch (type) {
    case 'lease':
      return <CheckCircleOutlined />;
    case 'expense':
      return <DollarOutlined />;
    case 'purchase':
      return <HomeOutlined />;
    default:
      return <CheckCircleOutlined />;
  }
};

const getEventColor = (type: string) => {
  switch (type) {
    case 'lease':
      return 'green';
    case 'expense':
      return 'orange';
    case 'purchase':
      return 'blue';
    default:
      return 'default';
  }
};

// Calendar component with view-only modal for leasing data
const Calendar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const status = useSelector((state: RootState) => state.leasings.status);
  const expenses = useSelector((state: RootState) => state.expenses.data);
  const properties = useSelector((state: RootState) => state.properties.data);
  
  // State to manage modal visibility and selected leasing
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<CalendarEvent[]>([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);

  // Fetch leasing data on mount
  // useEffect(() => {
  //   if (status === 'idle') {
  //     dispatch(fetchLeasings());
  //   }
  // }, [status, dispatch]);

  // Handle date click to show modal with leasing details
  const handleDateClick = (events: CalendarEvent[], date: Dayjs) => {
    setSelectedEvents(events);
    setSelectedDate(date);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedEvents([]);
    setSelectedDate(null);
  };

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column', height: '100%'  }}>
      {/* Calendar Container */}
      <div style={{ height: 'calc(100vh - 185px)', overflow: 'hidden' }}> {/* Dynamic height calculation */}
        <AntCalendar  dateCellRender={(date) => dateCellRender(date, leasings, expenses, properties, handleDateClick)} />
      </div>
  
      <Modal
        title={`Events for ${selectedDate?.format('MMMM D, YYYY')}`}
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
        width={600}
        style={{ top: 20 }}
      >
        {selectedEvents.length > 0 ? (
          selectedEvents.map((event) => (
            <div key={event.id} style={{ marginBottom: '24px', padding: '20px', borderRadius: '8px', backgroundColor: '#f8f9fa', boxShadow: '0 2px 4px rgba(0,0,0,0.05)' }}>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                <div>
                  <h4 style={{ fontSize: '16px', marginBottom: '16px', color: '#1890ff' }}>
                    {event.property_name} - {event.title}
                  </h4>
                  {event.type === 'lease' && (
                    <>
                      <p style={{ margin: '8px 0' }}>
                        <strong style={{ color: '#333' }}>Tenant:</strong> {event.tenant_name}
                      </p>
                      <p style={{ margin: '8px 0' }}>
                        <strong style={{ color: '#333' }}>Monthly Rent:</strong>{' '}
                        <span style={{ color: '#52c41a' }}>${event.monthly_rent?.toLocaleString()}</span>
                      </p>
                      <p style={{ margin: '8px 0' }}>
                        <strong style={{ color: '#333' }}>Lease Period:</strong>{' '}
                        {dayjs(event.lease_start_date).format('MMM D, YYYY')} - {dayjs(event.lease_end_date).format('MMM D, YYYY')}
                      </p>
                    </>
                  )}
                  {event.amount && event.type !== 'lease' && (
                    <p style={{ margin: '8px 0' }}>
                      <strong style={{ color: '#333' }}>Amount:</strong>{' '}
                      <span style={{ color: '#52c41a' }}>${event.amount.toLocaleString()}</span>
                    </p>
                  )}
                  {event.description && (
                    <p style={{ margin: '8px 0' }}>
                      <strong style={{ color: '#333' }}>Description:</strong> {event.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: 'center', padding: '40px 20px', color: '#999', backgroundColor: '#fafafa', borderRadius: '8px' }}>
            <p>No events for this date.</p>
          </div>
        )}
      </Modal>
    </Layout>
  );
};

export default Calendar;
