import React, { useEffect, useState } from 'react';
import { Button, Table, Space, message, Tabs, Form, Select, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReports, deleteReport, createReport } from '../redux/reportsSlice';
import { RootState } from '../redux/store';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Layout, Row, Col } from 'antd';

const { TabPane } = Tabs;

interface FormValues {
    type: string;
    year: number;
    groupBy?: 'monthly' | 'quarterly' | 'yearly';
    properties?: number[];
}

const Reports: React.FC = () => {
    const [form] = Form.useForm();
    const [activeTab, setActiveTab] = useState('explorer');
    const dispatch = useDispatch();
    const reports = useSelector((state: RootState) => state.reports.reports);
    const user = useSelector((state: RootState) => state.user.data);
    const leasings = useSelector((state: RootState) => state.leasings.data);
    const expenses = useSelector((state: RootState) => state.expenses.data);
    const [formValues, setFormValues] = useState<FormValues>({
        type: '',
        year: new Date().getFullYear(),
        groupBy: 'monthly'
    });
    const [showReport, setShowReport] = useState(false);

    useEffect(() => {
        dispatch(fetchReports() as any);
    }, [dispatch]);

    const handleDelete = (id: number) => {
        dispatch(deleteReport(id.toString()) as any);
        message.success('Report deleted successfully');
    };

    const columns = [
        {
            title: 'Report Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Created Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: any) => (
                <Space>
                    <Button onClick={() => handleDownloadPDF()}>Download</Button>
                    <Button danger onClick={() => handleDelete(record.id)}>Delete</Button>
                </Space>
            ),
        },
    ];

    const reportTypes = [
        'Annual Expense Report',
        'Annual Profit & Loss Statement',
        'Annual Property Revenue Analysis',
    ];

    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: 6 }, (_, i) => currentYear - i + 1);

    const handleSaveReport = () => {
        // Similar to your existing handleSave but adapted for the new UI
        // Show a modal/popover for naming the report
        message.info('Save functionality coming soon');
    };

    const handleDownloadPDF = () => {
        // Implement PDF download logic
        message.info('Download functionality coming soon');
    };

    const ExplorerTab = () => (
        <Layout>
        <Row gutter={[24, 24]}>
            <Card style={{ width: 300 }}>
                <Form 
                    form={form} 
                    layout="vertical"
                    initialValues={{
                        year: new Date().getFullYear(),
                        groupBy: 'monthly'
                    }}
                    onValuesChange={(_, allValues) => {
                        const hasRequiredFields = allValues.type && allValues.year;
                        setFormValues({
                            ...allValues,
                            groupBy: allValues.groupBy || 'monthly'
                        });
                        setShowReport(hasRequiredFields);
                    }}
                >
                    <Form.Item
                        name="type"
                        label="Report Type"
                        rules={[{ required: true }]}
                    >
                        <Select options={reportTypes.map(type => ({ label: type, value: type }))} />
                    </Form.Item>

                    <Form.Item
                        name="year"
                        label="Year"
                        initialValue={currentYear}
                        rules={[{ required: true }]}
                    >
                        <Select options={yearOptions.map(year => ({ label: year, value: year }))} />
                    </Form.Item>

                    {/* Add more filter options here */}
                    <Form.Item
                        name="groupBy"
                        label="Group By"
                    >
                        <Select
                            options={[
                                { label: 'Monthly', value: 'monthly' },
                                { label: 'Quarterly', value: 'quarterly' },
                                { label: 'Yearly', value: 'yearly' },
                            ]}
                        />
                    </Form.Item>

                    {/* <Form.Item
                        name="properties"
                        label="Properties"
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select properties"
                            options={leasings.map(lease => ({
                                label: lease.property_id.property_name,
                                value: lease.property_id.id
                            }))}
                        />
                    </Form.Item> */}
                </Form>
            </Card>

            <Card style={{ flex: 1 }}>
                <div style={{ marginBottom: 16, textAlign: 'right' }}>
                    {showReport && (
                        <>
                            <Button 
                                icon={<DownloadOutlined />} 
                                onClick={handleDownloadPDF}
                                style={{ marginRight: 8 }}
                            >
                                Download PDF
                            </Button>
                            <Button 
                                type="primary" 
                                icon={<SaveOutlined />}
                                onClick={handleSaveReport}
                            >
                                Save Report
                            </Button>
                        </>
                    )}
                </div>

                {/* Only show report if showReport is true */}
                {showReport && formValues.type === 'Annual Property Revenue Analysis' && <ReportDisplay />}
                {showReport && formValues.type === 'Annual Expense Report' && <ExpenseReportDisplay />}
                {showReport && formValues.type === 'Annual Profit & Loss Statement' && <ProfitLossDisplay />}
            </Card>
            </Row>
        </Layout>
    );

    const SavedReportsTab = () => (
        <Table 
            columns={columns} 
            dataSource={reports} 
            rowKey="id" 
        />
    );

    const ReportDisplay = () => {
        const { monthlyData, totalRevenue } = generateRevenueAnalysis();
        const properties = Array.from(new Set(leasings.map(lease => lease.property_id.id)));
        
        const propertyTotals: Record<number, number> = properties.reduce((acc, propertyId) => ({
            ...acc,
            [propertyId]: monthlyData.reduce((sum, month) => sum + (month[propertyId] || 0), 0)
        }), {});

        const columns = [
            { title: 'Month', dataIndex: 'month', key: 'month' },
            ...properties.map(propertyId => ({
                title: leasings.find(lease => 
                    lease.property_id.id === propertyId
                )?.property_id.property_name || `Property ${propertyId}`,
                dataIndex: propertyId,
                key: propertyId,
                render: (value: number) => value ? `$${value.toFixed(2)}` : '-'
            }))
        ];

        return (
            <>
                <Table 
                    dataSource={monthlyData} 
                    columns={columns} 
                    pagination={false}
                    size="small"
                    style={{ fontSize: '12px' }}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{ 
                                borderTop: '2px solid #f0f0f0',
                                fontWeight: 'bold'
                            }}>
                                <Table.Summary.Cell index={0}>Total (${totalRevenue.toFixed(2)})</Table.Summary.Cell>
                                {properties.map((propertyId, index) => (
                                    <Table.Summary.Cell key={propertyId} index={index + 1}>
                                        ${propertyTotals[propertyId].toFixed(2)}
                                    </Table.Summary.Cell>
                                ))}
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                />
            </>
        );
    };

    const ExpenseReportDisplay = () => {
        const { monthlyData, totalExpenses } = generateExpenseReport();
        const categories = Array.from(new Set(expenses.map(expense => expense.category)));
        
        // Add type definition for categoryTotals
        const categoryTotals: Record<string, number> = categories.reduce((acc, category) => {
            const total = monthlyData.reduce((sum, month) => sum + (month[category] || 0), 0);
            return { ...acc, [category]: total };
        }, {});

        const columns = [
            { title: 'Month', dataIndex: 'month', key: 'month' },
            ...categories.map(category => ({
                title: category,
                dataIndex: category,
                key: category,
                render: (value: number) => value ? `$${value.toFixed(2)}` : '-'
            }))
        ];

        return (
            <Table 
                dataSource={monthlyData} 
                columns={columns} 
                pagination={false}
                size="small"
                style={{ fontSize: '12px' }}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row style={{ 
                            borderTop: '2px solid #f0f0f0',
                            fontWeight: 'bold'
                        }}>
                            <Table.Summary.Cell index={0}>Total (${totalExpenses.toFixed(2)})</Table.Summary.Cell>
                            {categories.map((category, index) => (
                                <Table.Summary.Cell key={category} index={index + 1}>
                                    ${categoryTotals[category].toFixed(2)}
                                </Table.Summary.Cell>
                            ))}
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        );
    };

    const ProfitLossDisplay = () => {
        const data = generateProfitLossStatement();
        
        const columns = [
            { 
                title: 'Month', 
                dataIndex: 'month', 
                key: 'month' 
            },
            { 
                title: 'Revenue', 
                dataIndex: 'revenue', 
                key: 'revenue',
                render: (value: number) => `$${value.toFixed(2)}`
            },
            { 
                title: 'Expenses', 
                dataIndex: 'expenses', 
                key: 'expenses',
                render: (value: number) => `$${value.toFixed(2)}`
            },
            { 
                title: 'Net Income', 
                dataIndex: 'netIncome', 
                key: 'netIncome',
                render: (value: number) => `$${value.toFixed(2)}`
            }
        ];

        // Calculate totals
        const totals = data.reduce((acc, row) => ({
            revenue: acc.revenue + row.revenue,
            expenses: acc.expenses + row.expenses,
            netIncome: acc.netIncome + row.netIncome
        }), { revenue: 0, expenses: 0, netIncome: 0 });

        return (
            <div style={{ maxHeight: '80vh', overflow: 'auto' }}>

            <Table 
                dataSource={data} 
                columns={columns} 
                pagination={false}
                size="small"
                style={{ fontSize: '12px' }}
                summary={() => (
                    <Table.Summary fixed>
                        <Table.Summary.Row style={{ 
                            borderTop: '2px solid #f0f0f0',
                            fontWeight: 'bold'
                        }}>
                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>${totals.revenue.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>${totals.expenses.toFixed(2)}</Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                                <span style={{ 
                                    color: totals.netIncome >= 0 ? '#4caf50' : '#f44336'
                                }}>
                                    ${totals.netIncome.toFixed(2)}
                                </span>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
            </div>
        );
    };

    const generateRevenueAnalysis = () => {
        const properties = Array.from(new Set(leasings.map(lease => lease.property_id.id)));
        const selectedYear = formValues.year;
        
        // Helper function to group data
        const groupData = (monthlyData: any[]) => {
            if (!formValues.groupBy || formValues.groupBy === 'monthly') {
                return monthlyData;
            }

            if (formValues.groupBy === 'quarterly') {
                return Array.from({ length: 4 }, (_, i) => {
                    const quarterData: any = {
                        month: `Q${i + 1} ${selectedYear}`
                    };
                    
                    properties.forEach(propertyId => {
                        const quarterSum = monthlyData
                            .slice(i * 3, (i + 1) * 3)
                            .reduce((sum, month) => sum + (month[propertyId] || 0), 0);
                        if (quarterSum > 0) {
                            quarterData[propertyId] = quarterSum;
                        }
                    });
                    
                    return quarterData;
                });
            }

            if (formValues.groupBy === 'yearly') {
                return [{
                    month: `Year ${selectedYear}`,
                    ...properties.reduce((acc, propertyId) => ({
                        ...acc,
                        [propertyId]: monthlyData.reduce((sum, month) => sum + (month[propertyId] || 0), 0)
                    }), {})
                }];
            }
        };

        // Generate monthly data first
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        const monthlyData = months.map(month => {
            const rowData: any = { 
                month: new Date(selectedYear, month - 1).toLocaleString('default', { month: 'long' }) 
            };
            
            properties.forEach(propertyId => {
                const monthlyIncome = leasings
                    .filter(lease => {
                        const startDate = new Date(lease.lease_start_date);
                        const endDate = new Date(lease.lease_end_date);
                        const currentDate = new Date(selectedYear, month, 0);
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(23, 59, 59, 999);
                        
                        return lease.property_id.id === propertyId && 
                               lease.status === 'signed' &&
                               startDate <= currentDate && 
                               endDate >= new Date(selectedYear, month - 1, 1);
                    })
                    .reduce((sum, lease) => sum + lease.monthly_rent, 0);
                
                if (monthlyIncome > 0) {
                    rowData[propertyId] = monthlyIncome;
                }
            });
            
            return rowData;
        });

        // Group the data according to selection
        const groupedData = groupData(monthlyData) || monthlyData;

        // Calculate total revenue from grouped data
        const totalRevenue = groupedData.reduce((total, row) => {
            return total + Object.entries(row)
                .filter(([key, val]) => key !== 'month' && typeof val === 'number')
                .reduce((sum, [_, val]) => sum + (val as number), 0);
        }, 0);

        return {
            monthlyData: groupedData,
            totalRevenue
        };
    };

    const generateExpenseReport = () => {
        const categories = Array.from(new Set(expenses.map(expense => expense.category)));
        const selectedYear = formValues.year;

        // Helper function to group data
        const groupData = (monthlyData: any[]) => {
            if (!formValues.groupBy || formValues.groupBy === 'monthly') {
                return monthlyData;
            }

            if (formValues.groupBy === 'quarterly') {
                return Array.from({ length: 4 }, (_, i) => {
                    const quarterData: any = {
                        month: `Q${i + 1} ${selectedYear}`
                    };
                    
                    categories.forEach(category => {
                        quarterData[category] = monthlyData
                            .slice(i * 3, (i + 1) * 3)
                            .reduce((sum, month) => sum + (month[category] || 0), 0);
                    });
                    
                    return quarterData;
                });
            }

            if (formValues.groupBy === 'yearly') {
                return [{
                    month: `Year ${selectedYear}`,
                    ...categories.reduce((acc, category) => ({
                        ...acc,
                        [category]: monthlyData.reduce((sum, month) => sum + (month[category] || 0), 0)
                    }), {})
                }];
            }
        };

        // Generate monthly data first
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        const monthlyData = months.map(month => {
            const monthExpenses = expenses.filter(expense => {
                const expenseDate = new Date(expense.date);
                return expenseDate.getFullYear() === selectedYear && expenseDate.getMonth() + 1 === month;
            });

            const monthData: any = {
                month: new Date(selectedYear, month - 1).toLocaleString('default', { month: 'long' })
            };

            categories.forEach(category => {
                monthData[category] = monthExpenses
                    .filter(expense => expense.category === category)
                    .reduce((sum, expense) => sum + expense.amount, 0);
            });

            return monthData;
        });

        // Group the data according to selection
        const groupedData = groupData(monthlyData) || monthlyData;

        // Calculate total expenses from grouped data
        const totalExpenses = expenses
            .filter(expense => new Date(expense.date).getFullYear() === selectedYear)
            .reduce((sum, expense) => sum + expense.amount, 0);

        return { monthlyData: groupedData, totalExpenses };
    };

    const generateProfitLossStatement = () => {
        const selectedYear = formValues.year;
        
        // Generate monthly data first
        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        const monthlyData = months.map(month => {
            const monthRevenue = generateRevenueAnalysis().monthlyData[month - 1];
            const monthExpenses = generateExpenseReport().monthlyData[month - 1];
            
            const revenue = Object.values(monthRevenue)
                .filter(val => typeof val === 'number')
                .reduce((sum: number, val: any) => sum + val, 0);
                
            const expenses = Object.values(monthExpenses)
                .filter(val => typeof val === 'number')
                .reduce((sum: number, val: any) => sum + val, 0);

            return {
                month: new Date(selectedYear, month - 1).toLocaleString('default', { month: 'long' }),
                revenue,
                expenses,
                netIncome: revenue - expenses
            };
        });

        // Group the data according to selection
        if (formValues.groupBy === 'quarterly') {
            return Array.from({ length: 4 }, (_, i) => {
                const quarterMonths = monthlyData.slice(i * 3, (i + 1) * 3);
                const revenue = quarterMonths.reduce((sum, month) => sum + month.revenue, 0);
                const expenses = quarterMonths.reduce((sum, month) => sum + month.expenses, 0);
                
                return {
                    month: `Q${i + 1} ${selectedYear}`,
                    revenue,
                    expenses,
                    netIncome: revenue - expenses
                };
            });
        }

        if (formValues.groupBy === 'yearly') {
            const revenue = monthlyData.reduce((sum, month) => sum + month.revenue, 0);
            const expenses = monthlyData.reduce((sum, month) => sum + month.expenses, 0);
            
            return [{
                month: `Year ${selectedYear}`,
                revenue,
                expenses,
                netIncome: revenue - expenses
            }];
        }

        return monthlyData;
    };

    return (
        <Layout style={{ padding: '10px' }}>
            
            <Tabs activeKey={activeTab} onChange={setActiveTab}>
                <TabPane tab="Report Explorer" key="explorer">
                    <ExplorerTab />
                </TabPane>
                <TabPane tab="Saved Reports" key="saved">
                    <SavedReportsTab />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

export default Reports; 