import React from 'react';
import { Routes, Route} from 'react-router-dom';
import Dashboard from '../views/Dashboard'
import Leasings from '../views/Leasings'
import Properties from '../views/Properties'
import Tenants from '../views/Tenants'
import Messages from '../views/Messages';
import Calendar from '../views/Calendar';
import Expenses from '../views/Expenses';
import Overview from '../views/Overview';
import LandingPage from '../views/LandingPage';
import PropertyDetailsPage from './PropertyDetails';
import Reports from '../views/Reports';

const PageContent = () => {

    // const {
    //     token: { colorBgContainer, borderRadiusLG },
    // } = theme.useToken();

    return (
        <div style={{
            height: 'calc(100% - 80px)',
        }}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/leases" element={<Leasings />} />
              <Route path="/properties" element={<Properties />} />
              <Route path="/properties/:id" element={<PropertyDetailsPage />} />
              <Route path="/tenants" element={<Tenants />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/expenses" element={<Expenses />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/overview" element={<Overview />} />
              {/* Add more routes as needed */}
            </Routes>
        </div>
    );
};

export default PageContent;