import React, {useState} from 'react';
import { Button, List, Modal, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { ExpenseData } from '../redux/expensesSlice';
import { LeasingData } from '../redux/leasingsSlice';
import { TenantData } from '../redux/tenantsSlice';
import { RootState } from '../redux/store';
import { PropertyData } from '../redux/propertiesSlice';
const { Text } = Typography;


interface Activity {
  type: 'expense' | 'leasing' | 'tenant' | 'property';
  date: string;
  content: React.ReactNode;
  isNew: boolean;
}

interface RecentActivityProps {
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
}

const RecentActivity: React.FC<RecentActivityProps> = ({ isModalVisible, setIsModalVisible }) => {
  const expenses = useSelector((state: RootState) => state.expenses.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const tenants = useSelector((state: RootState) => state.tenants.data);
  const properties = useSelector((state: RootState) => state.properties.data);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const createActivity = <T extends { date_created?: string; date_updated?: string }>(
    item: T,
    type: Activity['type'],
    contentCreator: (item: T, isNew: boolean) => React.ReactNode
  ): Activity => {
    const isNew = !item.date_updated;
    const date = isNew ? item.date_created : item.date_updated;
    
    return {
      type,
      date: date || '',
      isNew,
      content: contentCreator(item, isNew),
    };
  };

  const createActivities = (): Activity[] => {
    const activities: Activity[] = [
      ...expenses.map((expense: ExpenseData) =>
        createActivity(expense, 'expense', (e, isNew) => (
          <span>
            {isNew ? 'New' : 'Updated'} expense: <Text strong>{`$${e.amount.toFixed(2)}`}</Text> for{' '}
            <Text strong>{e.category}</Text>
          </span>
        ))
      ),
      ...leasings.map((leasing: LeasingData) =>
        createActivity(leasing, 'leasing', (l, isNew) => (
          <span>
            {isNew ? 'New' : 'Updated'} lease for <Text strong>{l.property_id.property_name}</Text>
          </span>
        ))
      ),
      ...tenants.map((tenant: TenantData) =>
        createActivity(tenant, 'tenant', (t, isNew) => (
          <span>
            {isNew ? 'New' : 'Updated'} tenant: <Text strong>{t.full_name}</Text>
          </span>
        ))
      ),
      ...properties.map((property: PropertyData) =>
        createActivity(property, 'property', (p, isNew) => (
          <span>
            {isNew ? 'New' : 'Updated'} property: <Text strong>{p.property_name}</Text>
          </span>
        ))
      ),
    ];

    // Filter out activities with empty dates
    const validActivities = activities.filter(activity => activity.date);

    // Sort activities by date, most recent first
    return validActivities.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  };

  const allActivities = createActivities();
  const recentActivities = allActivities.slice(0, 3); // Get 3 most recent activities

  const ActivityList: React.FC<{ activities: Activity[], itemStyle?: React.CSSProperties }> = ({ activities, itemStyle }) => (
    <List
      dataSource={activities}
      renderItem={(item) => (
        <List.Item style={itemStyle}>
          <div>
            {item.content}
            <br />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {item.isNew ? 'Created' : 'Updated'} on {formatDate(item.date)}
            </Text>
          </div>
        </List.Item>
      )}
    />
  );

  return (
    <div>
      <ActivityList activities={recentActivities} itemStyle={{ padding: '4px 0' }} />
      <Modal
        title="All Recent Activities"
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={600}
      >
        <ActivityList activities={allActivities} />
      </Modal>
    </div>
  );
};

export default RecentActivity;