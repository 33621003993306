import React from 'react';
import { List, Avatar, Typography, Divider } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';

// Define TypeScript interface for message data
interface MessageData {
  key: string;
  sender: string;
  subject: string;
  snippet: string;
  timestamp: string;
}

// Example message data
const messages: MessageData[] = [
  {
    key: '1',
    sender: 'Alice Johnson',
    subject: 'Meeting Reminder',
    snippet: 'Don\'t forget our meeting tomorrow at 10 AM.',
    timestamp: '2024-08-31 09:00',
  },
  {
    key: '2',
    sender: 'Bob Smith',
    subject: 'Invoice Attached',
    snippet: 'Please find the attached invoice for your review.',
    timestamp: '2024-08-30 14:15',
  },
  {
    key: '3',
    sender: 'Charlie Brown',
    subject: 'Newsletter Subscription',
    snippet: 'Thank you for subscribing to our newsletter.',
    timestamp: '2024-08-29 11:20',
  },
];

// Message List component
const Messages = () => {
  return (
    <div>
      <Typography.Title level={3}>Messages</Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={messages}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.sender}
              description={
                <>
                  <Typography.Text strong>{item.subject}</Typography.Text>
                  <Divider type="vertical" />
                  <Typography.Text type="secondary">{item.snippet}</Typography.Text>
                </>
              }
            />
            <div style={{ whiteSpace: 'nowrap' }}>{item.timestamp}</div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Messages;
