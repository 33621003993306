import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Table, Button, Space, Modal, Form, Input, InputNumber, notification, Select, DatePicker, Upload, Divider, List } from 'antd';
import { EditOutlined, DeleteOutlined, TagOutlined, PlusOutlined, InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLeasings, addLeasing, updateLeasing, deleteLeasing, LeasingData } from '../redux/leasingsSlice';
import { RootState, AppDispatch } from '../redux/store';
import { fetchPropertyFiles, uploadPropertyFile, deletePropertyFile } from '../redux/filesSlice';
import { readAssetRaw } from '@directus/sdk';
import directus from '../utils/directusClient';
import { UploadChangeParam } from 'antd/es/upload';
import dayjs from 'dayjs';
import { LeasingStatus } from '../utils/options';
import { fetchTenants } from '../redux/tenantsSlice';
import { ColumnsType } from 'antd/es/table';

const { confirm } = Modal;

type FileType = {
  id: string;
  filename_download: string;
  type: string;
  filesize: number;
};


const Leasing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const tenants = useSelector((state: RootState) => state.tenants.data);
  const files = useSelector((state: RootState) =>
    state.files.files.filter(file => file.entity_type === 'leasing')
  );
  const error = useSelector((state: RootState) => state.leasings.error);
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingLeasing, setEditingLeasing] = useState<LeasingData | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [imageUrls, setImageUrls] = useState<Record<number, string[]>>({});
  const [fileList, setFileList] = useState<any[]>([]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<any>(null);
  
  const filesRef = useRef(files);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchLeasings());
    dispatch(fetchTenants());
    if (editingLeasing) {
      dispatch(fetchPropertyFiles());
    }
  }, [dispatch, editingLeasing]);

  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: error,
      });
    }
  }, [error]);

  useEffect(() => {
    if (Object.keys(imageUrls).length === 0 || Object.values(imageUrls).flat().length !== files.length) {
      filesRef.current = files;
      if (files.length > 0) {
        fetchImages();
      }
    }
  }, [files]);

  useEffect(() => {
    return () => {
      // Cleanup URLs when component unmounts
      Object.values(imageUrls).forEach(urls => {
        urls.forEach(url => URL.revokeObjectURL(url));
      });
    };
  }, [imageUrls]);

  const fetchImages = useCallback(async () => {
    const urlMap: Record<number, string[]> = {};

    for (const file of filesRef.current) {
      try {
        const response = await directus.request(readAssetRaw(file.id));
        const reader = response.getReader();
        const chunks: Uint8Array[] = [];

        let done = false;
        while (!done) {
          const { value, done: isDone } = await reader.read();
          done = isDone;
          if (value) {
            chunks.push(value);
          }
        }

        const blob = new Blob(chunks, { type: 'image/jpeg' });
        const url = URL.createObjectURL(blob);
        
        const entityId = file.entity_id;
        if (!urlMap[entityId]) {
          urlMap[entityId] = [];
        }
        urlMap[entityId].push(url);
      } catch (error) {
        console.error(`Error fetching image for file ${file.id}:`, error);
      }
    }

    setImageUrls(urlMap);
  }, []);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };

  const handleCancelPreview = () => setPreviewVisible(false);

  const handleEdit = (leasing: LeasingData) => {
    setEditingLeasing(leasing);
    setIsModalVisible(true);
    form.setFieldsValue({
      ...leasing,
      property: leasing.property_id.property_name, 
      tenant_id: leasing.tenant_id.id,
      lease_start_date: leasing.lease_start_date ? dayjs(leasing.lease_start_date) : null,
      lease_end_date: leasing.lease_end_date ? dayjs(leasing.lease_end_date) : null,
    });
  };

  const handleDelete = (id: number) => {
    confirm({
      title: 'Are you sure you want to delete this leasing record?',
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(deleteLeasing(id))
          .unwrap()
          .then(() => {
            notification.success({
              message: 'Leasing deleted',
              description: 'The leasing has been successfully deleted.',
            });
          })
          .catch((error) => {
            notification.error({
              message: 'Delete failed',
              description: error.message || 'An error occurred while deleting the leasing.',
            });
          });
      },
    });
  };

  const handlePhotoUpload = async (options: any, record: LeasingData) => {
    const { file } = options;

    try {
      await dispatch(uploadPropertyFile({
        file,
        entityId: record.id || 0,
        entityType: "leasing",
        teamId: record.team_id || 0,
        folderName: 'leasings'
      }));
      notification.success({ message: 'Document uploaded successfully' });
      dispatch(fetchPropertyFiles());
    } catch (error: any) {
      notification.error({ message: 'Failed to upload document', description: error.message });
    }
  };

  const handleConfirmDelete = async () => {
    if (fileToDelete) {
      try {
        await dispatch(deletePropertyFile(fileToDelete.uid));
        notification.success({ message: 'Document deleted successfully' });
        dispatch(fetchPropertyFiles());
      } catch (error: any) {
        notification.error({ message: 'Failed to delete document', description: error.message });
      } finally {
        setIsDeleteModalVisible(false);
        setFileToDelete(null);
      }
    }
  };

  const showDeleteConfirm = (file: any) => {
    setFileToDelete(file);
    setIsDeleteModalVisible(true);
  };

  const handleModalOk = (values: any) => {
    if (!editingLeasing) return;

    const updatedLeasing: LeasingData = {
      ...editingLeasing,
      tenant_id: {
        ...editingLeasing.tenant_id,
        id: values.tenant_id,
        full_name: tenants.find(t => t.id === values.tenant_id)?.full_name || editingLeasing.tenant_id.full_name,
      },
      lease_start_date: values.lease_start_date ? values.lease_start_date.format('YYYY-MM-DD') : null,
      lease_end_date: values.lease_end_date ? values.lease_end_date.format('YYYY-MM-DD') : null,
      monthly_rent: values.monthly_rent,
      status: values.status,
    };

    dispatch(updateLeasing(updatedLeasing))
      .unwrap()
      .then(() => {
        notification.success({
          message: 'Leasing updated',
          description: 'The leasing has been successfully updated.',
        });
        setIsModalVisible(false);
      })
      .catch((error) => {
        notification.error({
          message: 'Update failed',
          description: error.message || 'An error occurred while updating the leasing.',
        });
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setEditingLeasing(null);
    form.resetFields();
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const isImageFile = (fileType: string) => {
    return fileType.startsWith('image/');
  };

  const renderUploadComponent = (record: LeasingData) => {
    const relatedFiles = files.filter(file => file.entity_id === record.id);
    const recordImageUrls = imageUrls[record.id || 0] || [];
    
    return (
      <div>
        <div style={{ marginBottom: 16, width: '300px', height: '100px' }}>
          <Upload.Dragger
            customRequest={(options) => handlePhotoUpload(options, record)}
            showUploadList={false}
            accept=".pdf,.doc,.docx,.xls,.xlsx,.txt,image/*"
            style={{ padding: '8px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <p className="ant-upload-drag-icon" style={{ marginTop: '0', marginBottom: '4px', textAlign: 'center' }}>
              <InboxOutlined />
            </p>
            <p className="ant-upload-text" style={{ marginBottom: '0', textAlign: 'center' }}>Click or drag files to upload</p>
          </Upload.Dragger>
        </div>

        <div style={{ display: 'flex', gap: '24px' }}>
          {/* Image files */}
          <div style={{ flex: 1 }}>
            <h5>Images:</h5>
            {relatedFiles.filter(file => isImageFile(file.type)).length > 0 ? (
              <Upload
                listType="picture-card"
                fileList={
                  relatedFiles
                    .filter(file => isImageFile(file.type))
                    .map((file, index) => ({
                      uid: file.id,
                      name: file.filename_download,
                      status: 'done',
                      url: recordImageUrls[index],
                      type: file.type,
                    }))
                }
                showUploadList={{ 
                  showPreviewIcon: true, 
                  showRemoveIcon: true,
                  showDownloadIcon: true
                }}
                customRequest={(options) => handlePhotoUpload(options, record)}
                onRemove={file => showDeleteConfirm({ ...file, id: file.uid })}
                onPreview={handlePreview}
                onDownload={(file) => {
                  const originalFile = relatedFiles.find(f => f.id === file.uid);
                  if (originalFile) handleDownload(originalFile);
                }}
              />
            ) : (
              <div style={{ textAlign: 'center', padding: '20px', color: '#999' }}>
                <p style={{ margin: 0 }}>No images uploaded yet</p>
              </div>
            )}
          </div>

          {/* Document files */}
          <div style={{ flex: 1 }}>
            <h5>Documents:</h5>
            <List
              dataSource={relatedFiles.filter(file => !isImageFile(file.type))}
              locale={{ 
                emptyText: (
                  <div style={{ textAlign: 'center', padding: '5px', color: '#999' }}>
                    <p style={{ margin: 0 }}>No documents uploaded yet</p>
                  </div>
                ) 
              }}
              renderItem={file => (
                <List.Item
                  actions={[
                    <Button 
                      icon={<DownloadOutlined />} 
                      onClick={() => handleDownload(file)}
                    />,
                    <Button 
                      icon={<DeleteOutlined />} 
                      onClick={() => showDeleteConfirm({ ...file, uid: file.id })}
                    />
                  ]}
                >
                  <List.Item.Meta
                    title={file.filename_download}
                    description={`${(file.filesize / 1024).toFixed(2)} KB`}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>

        <Modal
          title="Confirm Delete"
          open={isDeleteModalVisible}
          onOk={handleConfirmDelete}
          onCancel={() => setIsDeleteModalVisible(false)}
          okText="Delete"
          cancelText="Cancel"
          centered
        >
          <p>Are you sure you want to delete this file?</p>
        </Modal>

        <Modal
          open={previewVisible}
          footer={null}
          onCancel={handleCancelPreview}
          centered
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  };

  const handleDownload = async (file: FileType) => {
    try {
      const response = await directus.request(readAssetRaw(file.id));
      const reader = response.getReader();
      const chunks: Uint8Array[] = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        if (value) chunks.push(value);
      }

      const blob = new Blob(chunks, { type: file.type });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.filename_download;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      notification.error({
        message: 'Download failed',
        description: 'Failed to download the file'
      });
    }
  };

  useEffect(() => {
    return () => {
      Object.values(imageUrls).forEach(urls => {
        urls.forEach(url => URL.revokeObjectURL(url));
      });
    };
  }, [imageUrls]);

  const expandedRowRender = (record: LeasingData) => {
    return (
      <div>
        <h4>Documents:</h4>
        <div>
          {renderUploadComponent(record)}
        </div>
        {/* <Divider />
        <h4>Additional Details:</h4>
        <p>
          <strong>Property:</strong> {record.property_id?.property_name}<br />
          <strong>Tenant:</strong> {record.tenant_id?.full_name}<br />
          <strong>Lease Period:</strong> {dayjs(record.lease_start_date).format('YYYY-MM-DD')} to {dayjs(record.lease_end_date).format('YYYY-MM-DD')}
        </p> */}
      </div>
    );
  };

  const columns: ColumnsType<LeasingData> = [
    { 
      title: 'Property', 
      dataIndex: ['property_id', 'property_name'], 
      key: 'property_name',
      render: (text: any, record: LeasingData) => <Space><span style={{ fontWeight: 'bold' }}>{record.property_id?.property_name || 'N/A'}</span></Space>,
      filters: Array.from(new Set(leasings.map(l => l.property_id?.property_name))).map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.property_id?.property_name?.indexOf(value as string) === 0,
    },
    { 
      title: 'Lease Start Date', 
      dataIndex: 'lease_start_date', 
      key: 'lease_start_date',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
      sorter: (a, b) => dayjs(a.lease_start_date).unix() - dayjs(b.lease_start_date).unix(),
    },
    { 
      title: 'Lease End Date', 
      dataIndex: 'lease_end_date', 
      key: 'lease_end_date',
      render: (date: string) => dayjs(date).format('YYYY-MM-DD'),
      sorter: (a, b) => dayjs(a.lease_end_date).unix() - dayjs(b.lease_end_date).unix(),
    },
    { 
      title: 'Monthly Rent', 
      dataIndex: 'monthly_rent', 
      key: 'monthly_rent', 
      render: (text: number) => `$${text.toFixed(2)}`,
      sorter: (a, b) => a.monthly_rent - b.monthly_rent,
    },
    { 
      title: 'Tenant Name', 
      dataIndex: ['tenant_id', 'full_name'], 
      key: 'tenant_name',
      filters: Array.from(new Set(leasings.map(l => l.tenant_id?.full_name))).map(name => ({ text: name, value: name })),
      onFilter: (value, record) => record.tenant_id?.full_name.indexOf(value as string) === 0,
    },
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status',
      render: (status: string) => {
        switch (status) {
          case LeasingStatus.SIGNED:
            return <>Signed <CheckOutlined style={{ color: 'green' }} /></>;
          case LeasingStatus.PENDING:
            return <>Pending <ClockCircleOutlined style={{ color: 'orange' }} /></>;
          case LeasingStatus.CANCELLED:
            return <>Cancelled <CloseOutlined style={{ color: 'red' }} /></>;
          default:
            return <>New <TagOutlined style={{ color: 'blue' }} /></>;
        }
      },
      filters: Object.values(LeasingStatus).map(status => ({ text: status, value: status })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button 
            icon={<EditOutlined style={{color:"black"}} />} 
            size="small" 
            type='link' 
            onClick={() => handleEdit(record)} 
          />
          <Button 
            icon={<DeleteOutlined style={{color:"black"}} />} 
            size="small" 
            type='link' 
            onClick={() => handleDelete(record.id)} 
          />
        </Space>
      ),
    },
  ];

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <h1>Leases</h1>
      <Table 
        columns={columns} 
        dataSource={leasings} 
        rowKey="id" 
        expandable={{ expandedRowRender }}
        style={{ width: '100%' }} 
      />

      <Modal
        title={editingLeasing ? 'Edit Leasing' : 'Add Leasing'}
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {editingLeasing && (
          <Form
            form={form}
            initialValues={{
              ...editingLeasing,
              tenant_id: editingLeasing.tenant_id.id,
              lease_start_date: dayjs(editingLeasing.lease_start_date),
              lease_end_date: dayjs(editingLeasing.lease_end_date),
            }}
            onFinish={handleModalOk}
            layout="vertical"
          >
            <Form.Item
              label="Property"
              name={['property_id', 'property_name']}
              rules={[{ required: true, message: 'Please select the property!' }]}
              style={{ width: '50%' }}
            >
              <Input readOnly style={{color:'grey'}} />
            </Form.Item>
            <Form.Item
              label="Tenant Name"
              name="tenant_id"
              rules={[{ required: true, message: 'Please select a tenant!' }]}
              style={{ width: '50%' }}
            >
              <Select
                placeholder="Select Tenant"
                options={tenants.map(tenant => ({
                  label: tenant.full_name,
                  value: tenant.id,
                }))}
              />
            </Form.Item>
            <Form.Item
              label="Lease Start Date"
              name="lease_start_date"
              rules={[{ required: true, message: 'Please input the lease start date!' }]}
              style={{ width: '50%' }}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
              label="Lease End Date"
              name="lease_end_date"
              rules={[{ required: true, message: 'Please input the lease end date!' }]}
              style={{ width: '50%' }}
            >
              <DatePicker format="YYYY-MM-DD" />
            </Form.Item>
            <Form.Item
              label="Monthly Rent"
              name="monthly_rent"
              rules={[{ required: true, message: 'Please input the monthly rent!' }]}
              style={{ width: '50%' }}
            >
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: 'Please select the status!' }]}
              style={{ width: '50%' }}
            >
              <Select>
                {Object.keys(LeasingStatus).map((key) => {
                  const statusKey = key as keyof typeof LeasingStatus;
                  return (
                    <Select.Option key={LeasingStatus[statusKey]} value={LeasingStatus[statusKey]}>
                      {LeasingStatus[statusKey]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item label="Upload Documents">
              {editingLeasing && renderUploadComponent(editingLeasing)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </Space>
  );
};

export default Leasing;