import React from 'react';
import { Layout, Button, Typography, Card, Row, Col, Space, Divider } from 'antd';
import { CheckOutlined, RightOutlined, HomeOutlined, MessageOutlined, SearchOutlined, ThunderboltOutlined, DollarOutlined, AimOutlined, RobotOutlined, LineChartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import dashboardImage from '../assets/dashboard.png';
import suggestionsImage from '../assets/suggestions.png';
import overviewImage from '../assets/overview_2.png';
import assistantImage from '../assets/assistant.png';
import { getAppUrl } from '../utils/urlHelper';
const { Header, Content } = Layout;
const { Title, Text, Paragraph } = Typography;

interface DecodedToken {
  exp: number;
}
const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleAuthNavigation = (path: string) => {
    const token = localStorage.getItem('authToken');
    if (token && !isTokenExpired(token)) {
      window.location.href = getAppUrl('/dashboard');
    } else {
      window.location.href = getAppUrl(path);
    }
  };

  const pricingTiers = [
    {
      title: 'Starter',
      price: '5.90',
      features: [
        'Up to 5 properties',
      ],
      buttonText: 'Start 30-Day Free Trial',
      highlighted: false,
      originalPrice: '5.90',
      trialText: 'No credit card required'
    },
    {
      title: 'Professional',
      price: '19',
      originalPrice: '19',
      features: [
        'Up to 50 properties',
      ],
      buttonText: 'Start 30-Day Free Trial',
      highlighted: true
    },
    {
      title: 'Enterprise',
      price: 'Custom',
      originalPrice: 'Custom',
      features: [
        'Unlimited properties',
      ],
      buttonText: 'Contact Sales',
      highlighted: false,
      calendlyLink: 'https://calendly.com/oorgad/blurben-intro'
    }
  ];

  return (
    <Layout>
      <Header style={{ background: '#1C4E80', padding: '0 50px' }}>
        <Row justify="space-between" align="middle" style={{ height: '100%' }}>
          <Title level={3} style={{ color: 'white', margin: 0 }}>
            <HomeOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
            Blurben
          </Title>
          <Space>
            <Button type="link" style={{ color: 'white' }} onClick={() => handleAuthNavigation('/login')}>
              Login
            </Button>
            <Button type="primary" ghost onClick={() => handleAuthNavigation('/signup')}>
              Sign Up Free
            </Button>
          </Space>
        </Row>
      </Header>

      <Content>
        {/* Hero Section */}
        <Row justify="center" align="middle" style={{ minHeight: '500px', padding: '60px 50px', background: '#f0f2f5' }}>
          <Col span={12}>
            <Title style={{ fontSize: '48px', marginBottom: '24px' }}>
              Manage Your Properties <br />with Confidence
            </Title>
            <Paragraph style={{ fontSize: '18px', marginBottom: '32px' }}>
              Streamline your property management workflow with our comprehensive solution. 
              Track tenants, leases, and expenses all in one place.
            </Paragraph>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
              <Button type="primary" size="large" onClick={() => handleAuthNavigation('/signup')}>
                Get Started Free <RightOutlined />
              </Button>
              <Text type="secondary" style={{ fontSize: '14px' }}>
                30-day trial • No credit card required
              </Text>
            </Space>
          </Col>
          <Col span={12}>
            <img 
              src={dashboardImage} 
              alt="Dashboard Preview" 
              style={{
                width: '100%',
                maxWidth: '600px',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                marginLeft: '40px'
              }}
            />
          </Col>
        </Row>

        {/* AI Assistant Section */}
        <Row 
          justify="center" 
          align="middle" 
          style={{ 
            padding: '80px 50px',
            background: 'white'
          }}
        >
          <Col span={12}>
            <img 
              src={suggestionsImage}
              alt="AI Assistant Preview" 
              style={{
                width: '100%',
                maxWidth: '500px',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '40px' }}>
            <Title level={2} style={{ marginBottom: '24px' }}>
              Daily AI Suggestions
            </Title>
            <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
              Experience the future of property management with our AI-powered assistant. Get intelligent insights and recommendations tailored to your properties.
            </Paragraph>
            <Space direction="vertical" size={16}>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <RobotOutlined style={{ color: '#2f54eb', fontSize: '24px', marginRight: '8px' }} />
                  24/7 AI Assistant
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  Get instant answers to your property management questions and automated support around the clock.
                </Text>
              </div>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <LineChartOutlined style={{ color: '#eb2f96', fontSize: '24px', marginRight: '8px' }} />
                  Daily AI Suggestions
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  Receive personalized recommendations and market analysis to optimize your property performance.
                </Text>
              </div>
              <Button type="primary" size="large" onClick={() => handleAuthNavigation('/signup')} style={{ marginTop: '24px' }}>
                Try for Free <RightOutlined />
              </Button>
              <Text type="secondary" style={{ fontSize: '14px' }}>
                30-day trial • No credit card required
              </Text>
            </Space>
          </Col>
        </Row>

        {/* Analytics Dashboard Section */}
        <Row 
          justify="center" 
          align="middle" 
          style={{ 
            padding: '80px 50px',
            background: '#f8f9fa'  // Light gray background for contrast
          }}
        >
          <Col span={12} style={{ paddingRight: '40px' }}>
            <Title level={2} style={{ marginBottom: '24px' }}>
              Comprehensive Analytics Dashboard
            </Title>
            <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
              Get a clear view of your property portfolio performance with our intuitive analytics dashboard.
            </Paragraph>
            <Space direction="vertical" size={16}>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <DollarOutlined style={{ color: '#13c2c2', fontSize: '24px', marginRight: '8px' }} /> 
                  Financial Insights
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  Track your revenue, expenses, and profitability with detailed yearly breakdowns and trend analysis.
                </Text>
              </div>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <AimOutlined style={{ color: '#722ed1', fontSize: '24px', marginRight: '8px' }} /> 
                  Performance Metrics
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  Monitor occupancy rates, maintenance costs, and rental yield across your entire portfolio.
                </Text>
              </div>
              <Button type="primary" size="large" onClick={() => handleAuthNavigation('/signup')} style={{ marginTop: '24px' }}>
                Start Free Trial <RightOutlined />
              </Button>
              <Text type="secondary" style={{ fontSize: '14px' }}>
                30-day trial • No credit card required
              </Text>
            </Space>
          </Col>
          <Col span={12}>
            <img 
              src={overviewImage}
              alt="Analytics Dashboard" 
              style={{
                width: '100%',
                maxWidth: '600px',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Col>
        </Row>

        {/* AI Chat Assistant Section */}
        <Row 
          justify="center" 
          align="middle" 
          style={{ 
            padding: '80px 50px',
            background: 'white'
          }}
        >
          <Col span={12}>
            <img 
              src={assistantImage}
              alt="AI Chat Assistant" 
              style={{
                width: '100%',
                maxWidth: '500px',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Col>
          <Col span={12} style={{ paddingLeft: '40px' }}>
            <Title level={2} style={{ marginBottom: '24px' }}>
              Your Personal Property AI Assistant
            </Title>
            <Paragraph style={{ fontSize: '18px', marginBottom: '24px' }}>
              Have a conversation with your data. Our AI chatbot understands your property portfolio and provides instant, accurate answers to all your questions.
            </Paragraph>
            <Space direction="vertical" size={16}>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <MessageOutlined style={{ color: '#1890ff', fontSize: '24px', marginRight: '8px' }} /> 
                  Ask Anything
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  "What's my rental income for the past quarter?" or "Show me properties with pending maintenance" - get immediate answers to any property-related question.
                </Text>
              </div>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <SearchOutlined style={{ color: '#52c41a', fontSize: '24px', marginRight: '8px' }} /> 
                  Deep Property Insights
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  From occupancy trends to financial forecasts, get detailed insights about your properties through natural conversation.
                </Text>
              </div>
              <div>
                <Title level={4} style={{ marginBottom: '8px' }}>
                  <ThunderboltOutlined style={{ color: '#faad14', fontSize: '24px', marginRight: '8px' }} /> 
                  Real-Time Updates
                </Title>
                <Text style={{ fontSize: '16px' }}>
                  Stay informed with instant access to your latest property data, tenant updates, and maintenance requests.
                </Text>
              </div>
              <Button type="primary" size="large" onClick={() => handleAuthNavigation('/signup')} style={{ marginTop: '24px' }}>
                Try AI Assistant Free <RightOutlined />
              </Button>
              <Text type="secondary" style={{ fontSize: '14px' }}>
                30-day trial • No credit card required
              </Text>
            </Space>
          </Col>
        </Row>

        {/* Pricing Section */}
        <div style={{ padding: '80px 50px', background: 'white' }}>
          <Title level={2} style={{ textAlign: 'center', marginBottom: '16px' }}>
            Simple, Transparent Pricing
          </Title>
          {/* <Paragraph style={{ 
            textAlign: 'center', 
            fontSize: '18px', 
            marginBottom: '48px',
            color: '#1C4E80',
            background: '#f0f7ff',
            padding: '12px',
            borderRadius: '6px',
            width: 'fit-content',
            margin: '0 auto 48px auto'
          }}>
            <strong>🎉 Early Bird Special:</strong> All plans are FREE for the next 6 months!
          </Paragraph> */}
          <Row gutter={[24, 24]} justify="center">
            {pricingTiers.map((tier) => (
              <Col span={8} key={tier.title}>
                <Card
                  style={{
                    height: '100%',
                    borderColor: tier.highlighted ? '#1C4E80' : undefined,
                    transform: tier.highlighted ? 'scale(1.05)' : undefined,
                  }}
                  bodyStyle={{ padding: '24px' }}
                >
                  <Title level={3}>{tier.title}</Title>
                  <div style={{ margin: '24px 0' }}>
                    {tier.price === 'Custom' ? (
                      <Text style={{ fontSize: '40px', fontWeight: 'bold' }}>Custom</Text>
                    ) : (
                      <>
                        <Text style={{ fontSize: '40px', fontWeight: 'bold' }}>${tier.price}</Text>
                        <Text>/month</Text>
                        {tier.trialText && (
                          <div style={{ 
                            marginTop: '8px',
                            padding: '4px 12px',
                            background: '#f0f7ff',
                            borderRadius: '4px',
                            display: 'inline-block'
                          }}>
                            <Text type="secondary" strong>{tier.trialText}</Text>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <Divider />
                  <ul style={{ listStyle: 'none', padding: 0, marginBottom: '24px' }}>
                    {tier.features.map((feature) => (
                      <li key={feature} style={{ margin: '12px 0' }}>
                        <CheckOutlined style={{ color: '#1C4E80', marginRight: '8px' }} />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <Button
                    type={tier.highlighted ? 'primary' : 'default'}
                    block
                    size="large"
                    onClick={() => tier.calendlyLink ? window.open(tier.calendlyLink, '_blank') : handleAuthNavigation('/signup')}
                  >
                    {tier.buttonText}
                  </Button>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

const isTokenExpired = (token: string): boolean => {
  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    const currentTime = Date.now() / 1000;
    
    if (decodedToken.exp === undefined) {
      return true;
    }
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

export default LandingPage; 