import React, { useEffect, useState } from 'react';
import { Layout, Dropdown, Button, Menu, Avatar, message, Spin, Modal } from 'antd';
import { DownOutlined, UserOutlined, LogoutOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../redux/userSlice';
import AddPropertyModal from './modals/AddPropertyModal';
import AddLeasingWizard from './modals/AddLeasingModal';
import AddTenantWizard from './modals/AddTenantModal';
import AddReportWizardProps from './modals/AddReportModal';
import AddExpenseWizardProps from './modals/AddExpenseModal';
import { AppDispatch, RootState } from '../redux/store'; // Assuming this is where the root state is defined
import {Typography, Space, Divider } from 'antd';
import { MailOutlined, TeamOutlined } from '@ant-design/icons';
import directus from '../utils/directusClient';
import AddReportWizard from './modals/AddReportModal';


const { Title, Text } = Typography;
const { Header } = Layout;

interface AppHeaderProps {
  isSidebarCollapsed: boolean;
}

const AppHeader: React.FC<AppHeaderProps> = ({ isSidebarCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formType, setFormType] = useState('');
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false); // New state for profile modal

  // Fetch the logged-in user details on component mount
  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Get user details and status from the Redux store
  const { data: user, status: userStatus } = useSelector((state: RootState) => state.user);

  const handleLogout = () => {
    // Clear the token from local storage
    directus.logout();

    // Optionally show a message
    // message.success('Logged out successfully');

    // Navigate to login page
    navigate('/login');
  };

  const handleMenuClick = (e: any) => {
    setFormType(e.key);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Handle user profile modal visibility
  const openProfileModal = () => {
    setIsProfileModalVisible(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="property">Property</Menu.Item>
      <Menu.Item key="lease">Lease</Menu.Item>
      <Menu.Item key="tenant">Tenant</Menu.Item>
      <Menu.Item key="expense">Expense</Menu.Item>
      {/* <Menu.Item key="report">Report</Menu.Item> */}
    </Menu>
  );

  const userMenu = (
    <Menu>
      <Menu.Item key="userinfo" icon={<InfoCircleOutlined />} onClick={openProfileModal}>
        User Info
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header style={{
        backgroundColor: '#f5f5f5', // Make header invisible
        padding: '0 20px',
        position: 'fixed',
        width: isSidebarCollapsed ? 'calc(100% - 50px)' : 'calc(100% - 200px)', // Adjust width based on sidebar state
        height: "55px",
        top: 0,
        left: isSidebarCollapsed ? 50 : 200, // Position to the right of the sidebar
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
          <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
            <Button
              style={{
                marginRight: '10px',
                backgroundColor: '#1C4E80', // Match sidebar background
                borderColor: '#1C4E80', // Match the background for consistency
                color: 'white',
                borderRadius: '4px',
                padding: '0 12px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
              }}
              onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#163D66'} // Slightly darker on hover
              onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#1C4E80'}
            >
              Create New <DownOutlined style={{ fontSize: '12px', marginLeft: '5px' }} />
            </Button>
          </Dropdown>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '20px', paddingTop: '5px' }}>
          <Dropdown overlay={userMenu} trigger={['click']} placement="bottomRight">
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              <span style={{ marginRight: '10px' }}>
                {userStatus === 'loading' ? <Spin size="small" /> : `${user?.first_name}`}
              </span>
              <Avatar size="default" icon={<UserOutlined />} />
            </div>
          </Dropdown>
        </div>
      </Header>

      {/* Modal for User Info */}
      <Modal
        title={null}
        visible={isProfileModalVisible}
        onCancel={closeProfileModal}
        footer={null}
        width={400}
        bodyStyle={{ padding: '24px' }}
      >
        {userStatus === 'loading' ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            {/* Profile Header */}
            <div style={{ 
              textAlign: 'center',
              padding: '20px 0',
              borderRadius: '8px',
              background: '#f8f9fa'
            }}>
              <Avatar 
                size={80} 
                icon={<UserOutlined />}
                style={{ 
                  backgroundColor: '#1C4E80',
                  marginBottom: '16px',
                  border: '4px solid white',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }} 
              />
              <Title level={4} style={{ margin: '0', marginBottom: '4px' }}>
                {user?.first_name} {user?.last_name}
              </Title>
              <Text type="secondary">Team Member</Text>
            </div>

            {/* Profile Details */}
            <div style={{ padding: '0 10px' }}>
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <MailOutlined style={{ color: '#1C4E80', fontSize: '18px' }} />
                  <div>
                    <Text type="secondary">Email</Text>
                    <div>
                      <Text strong>{user?.email}</Text>
                    </div>
                  </div>
                </div>

                {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <TeamOutlined style={{ color: '#1C4E80', fontSize: '18px' }} />
                  <div>
                    <Text type="secondary">Team</Text>
                    <div>
                      <Text strong>{user?.team_id?.name}</Text>
                    </div>
                  </div>
                </div> */}
              </Space>
            </div>
          </Space>
        )}
      </Modal>

      <AddPropertyModal
        visible={isModalVisible && formType === 'property'}
        onCancel={handleCancel}
      />
      <AddLeasingWizard
        visible={isModalVisible && formType === 'lease'}
        onCancel={handleCancel}
      />
      <AddTenantWizard
        visible={isModalVisible && formType === 'tenant'}
        onCancel={handleCancel}
      />
      <AddExpenseWizardProps
        visible={isModalVisible && formType === 'expense'}
        onCancel={handleCancel}
      />
      {/* <AddReportWizardProps
        visible={isModalVisible && formType === 'report'}
        onCancel={handleCancel}
      /> */}
    </>
  );
};

export default AppHeader;
