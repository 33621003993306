import React from 'react';
import { useSelector } from 'react-redux';
import { Layout, Row, Col, Card } from 'antd';
import { RootState } from '../redux/store';
import OverviewTopBar from '../components/OverviewTopBar';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { LeasingData } from '../redux/leasingsSlice';
import { ExpenseData } from '../redux/expensesSlice';
import { PropertyData } from '../redux/propertiesSlice';

// Common chart colors and styles - add at the top of the file
const chartColors = {
  revenue: '#1C4E80',    // Your primary navy blue
  expense: '#FF6B6B',    // Softer coral red
  count: '#4CAF50',      // Forest green
  pieColors: ['#1C4E80', '#4CAF50', '#FF6B6B', '#FFA726', '#5C6BC0']  // Navy, Green, Coral, Orange, Purple-blue
};

const commonLineChartProps = {
  height: 160,  // Reduced from 180
  margin: { top: 20, right: 30, left: 20, bottom: 20 }
};

// Revenue Over Time Chart
const RevenueOverTimeChart: React.FC<{ leasings: LeasingData[] }> = ({ leasings }) => {
  const currentDate = new Date();
  // Set current date to end of current month for accurate comparison
  const currentMonthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
  const revenueData = leasings.reduce((acc, lease) => {
    if (lease.status !== 'signed') return acc;
    
    const startDate = new Date(lease.lease_start_date);
    const endDate = new Date(lease.lease_end_date || currentMonthEnd);
    // Limit endDate to current month
    const effectiveEndDate = endDate > currentMonthEnd ? currentMonthEnd : endDate;
    
    // Iterate over each month from startDate to effectiveEndDate
    for (let date = new Date(startDate); date <= effectiveEndDate; date.setMonth(date.getMonth() + 1)) {
      const monthYear = date.toLocaleDateString('en', { year: 'numeric', month: 'short' });
      const existing = acc.find((item) => item.date === monthYear);
      
      if (existing) {
        existing.revenue += lease.monthly_rent;
      } else {
        acc.push({ date: monthYear, revenue: lease.monthly_rent, timestamp: date.getTime() });
      }
    }
    
    return acc;
  }, [] as { date: string; revenue: number; timestamp: number }[]);

  // Sort data by timestamp for chronological order
  revenueData.sort((a, b) => a.timestamp - b.timestamp);

  return (
    <ResponsiveContainer width="100%" height={commonLineChartProps.height}>
      <LineChart 
        data={revenueData}
        margin={commonLineChartProps.margin}
      >
        <defs>
          <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={chartColors.revenue} stopOpacity={0.1}/>
            <stop offset="95%" stopColor={chartColors.revenue} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid 
          strokeDasharray="3 3" 
          vertical={false} 
          stroke="#f0f0f0" 
        />
        <XAxis 
          dataKey="date" 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
        />
        <YAxis 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
          tickFormatter={(value) => `$${value.toLocaleString()}`}
        />
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.98)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
          }}
          labelStyle={{ fontWeight: 600, marginBottom: '4px' }}
        />
        <Line
          type="monotone"
          dataKey="revenue"
          stroke={chartColors.revenue}
          strokeWidth={2.5}
          dot={false}
          activeDot={{ r: 8, strokeWidth: 0 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};


// Expenses Over Time Chart
const ExpensesOverTimeChart: React.FC<{ expenses: ExpenseData[] }> = ({ expenses }) => {
  const expenseData = expenses.reduce((acc, expense) => {
    const date = new Date(expense.date);
    const monthYear = date.toLocaleDateString('en', { year: 'numeric', month: 'short' });
    const timestamp = date.getTime(); // Use timestamp for sorting
    
    const existing = acc.find((item) => item.date === monthYear);
    if (existing) {
      existing.expense += expense.amount;
    } else {
      acc.push({ date: monthYear, expense: expense.amount, timestamp });
    }
    return acc;
  }, [] as { date: string; expense: number; timestamp: number }[]);
  
  // Sort data chronologically by timestamp
  expenseData.sort((a, b) => a.timestamp - b.timestamp);
  
  return (
    <ResponsiveContainer width="100%" height={commonLineChartProps.height}>
      <LineChart 
        data={expenseData}
        margin={commonLineChartProps.margin}
      >
        <defs>
          <linearGradient id="colorExpense" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={chartColors.expense} stopOpacity={0.1}/>
            <stop offset="95%" stopColor={chartColors.expense} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid 
          strokeDasharray="3 3" 
          vertical={false} 
          stroke="#f0f0f0" 
        />
        <XAxis 
          dataKey="date" 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
        />
        <YAxis 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
          tickFormatter={(value) => `$${value.toLocaleString()}`}
        />
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.98)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
          }}
          labelStyle={{ fontWeight: 600, marginBottom: '4px' }}
        />
        <Line
          type="monotone"
          dataKey="expense"
          stroke={chartColors.expense}
          strokeWidth={2.5}
          dot={false}
          activeDot={{ r: 8, strokeWidth: 0 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
  
};

// Expenses Type Distribution Chart
const ExpensesTypeDistribution: React.FC<{ expenses: ExpenseData[] }> = ({ expenses }) => {
  const expenseCategories = expenses.reduce((acc, expense) => {
    const existing = acc.find((item) => item.category === expense.category);
    if (existing) {
      existing.amount += expense.amount;
    } else {
      acc.push({ category: expense.category, amount: expense.amount });
    }
    return acc;
  }, [] as { category: string; amount: number }[]);

  return (
    <ResponsiveContainer width="100%" height={160}>
      <PieChart>
        <Pie 
          data={expenseCategories} 
          dataKey="amount" 
          nameKey="category" 
          outerRadius={70}
          innerRadius={40}
          paddingAngle={0}
        >
          {expenseCategories.map((entry, index) => (
            <Cell 
              key={`cell-${index}`} 
              fill={chartColors.pieColors[index % chartColors.pieColors.length]}
              stroke="none"
            />
          ))}
        </Pie>
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.98)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            padding: '8px 12px'
          }}
          formatter={(value) => `$${value.toLocaleString()}`}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

// Properties Count Over Time Chart
const PropertiesCountOverTime: React.FC<{ properties: PropertyData[] }> = ({ properties }) => {
  // Step 1: Prepare raw monthly data with purchase dates
  let monthlyData = properties.reduce((acc, property) => {
    const purchaseDate = new Date(property.purchase_date || '');
    const monthYear = purchaseDate.toLocaleDateString('en', { year: 'numeric', month: 'short' });
    const existing = acc.find((item) => item.date === monthYear);
    if (existing) {
      existing.count += 1;
    } else {
      acc.push({ date: monthYear, count: 1 });
    }
    return acc;
  }, [] as { date: string; count: number }[]);

  // Step 2: Sort data by date to ensure accumulation order
  monthlyData = monthlyData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Step 3: Accumulate counts across months
  const accumulatedData = monthlyData.reduce((acc, curr, index) => {
    const prevCount = index > 0 ? acc[index - 1].count : 0;
    acc.push({ date: curr.date, count: curr.count + prevCount });
    return acc;
  }, [] as { date: string; count: number }[]);

  // Render the chart
  return (
    <ResponsiveContainer width="100%" height={commonLineChartProps.height}>
      <LineChart 
        data={accumulatedData}
        margin={commonLineChartProps.margin}
      >
        <defs>
          <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={chartColors.count} stopOpacity={0.1}/>
            <stop offset="95%" stopColor={chartColors.count} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid 
          strokeDasharray="3 3" 
          vertical={false} 
          stroke="#f0f0f0" 
        />
        <XAxis 
          dataKey="date" 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
        />
        <YAxis 
          axisLine={false}
          tickLine={false}
          style={{ fontSize: '12px', fontWeight: 500 }}
          tickMargin={10}
          tickFormatter={(value) => Math.round(value).toString()}
          allowDecimals={false}
        />
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.98)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)'
          }}
          labelStyle={{ fontWeight: 600, marginBottom: '4px' }}
        />
        <Line
          type="monotone"
          dataKey="count"
          stroke={chartColors.count}
          strokeWidth={2.5}
          dot={false}
          activeDot={{ r: 8, strokeWidth: 0 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};


// Properties Type Distribution Chart
const PropertiesTypeDistribution: React.FC<{ properties: PropertyData[] }> = ({ properties }) => {
  const propertyTypes = properties.reduce((acc, property) => {
    const existing = acc.find((item) => item.type === property.type);
    if (existing) {
      existing.count += 1;
    } else {
      acc.push({ type: property.type, count: 1 });
    }
    return acc;
  }, [] as { type: string; count: number }[]);

  return (
    <ResponsiveContainer width="100%" height={160}>
      <PieChart>
        <Pie 
          data={propertyTypes} 
          dataKey="count" 
          nameKey="type" 
          outerRadius={70}
          innerRadius={40}
          paddingAngle={0}
        >
          {propertyTypes.map((entry, index) => (
            <Cell 
              key={`cell-${index}`} 
              fill={chartColors.pieColors[index % chartColors.pieColors.length]}
              stroke="none"
            />
          ))}
        </Pie>
        <Tooltip 
          contentStyle={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.98)',
            border: 'none',
            borderRadius: '12px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            padding: '8px 12px'
          }}
          formatter={(value) => `${value.toLocaleString()}`}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

// Main Overview Component
const Overview: React.FC = () => {
  const properties = useSelector((state: RootState) => state.properties.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const expenses = useSelector((state: RootState) => state.expenses.data);

  return (
    <Layout style={{ padding: '24px', background: '#f8fafc' }}>
      <OverviewTopBar />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card 
            title="Revenue Over Time" 
            bordered={false}
            style={{ borderRadius: '16px' }}
            headStyle={{ borderBottom: 'none', fontSize: '16px', fontWeight: 600 }}
          >
            <RevenueOverTimeChart leasings={leasings} />
          </Card>
        </Col>
        <Col span={12}>
          <Card 
            title="Expenses Over Time" 
            bordered={false}
            style={{ borderRadius: '16px' }}
            headStyle={{ borderBottom: 'none', fontSize: '16px', fontWeight: 600 }}
          >
            <ExpensesOverTimeChart expenses={expenses} />
          </Card>
        </Col>
      </Row>
      <div style={{ height: '6px' }} />
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card 
            title="Properties Count Over Time" 
            bordered={false}
            style={{ borderRadius: '16px' }}
            headStyle={{ borderBottom: 'none', fontSize: '16px', fontWeight: 600 }}
          >
            <PropertiesCountOverTime properties={properties} />
          </Card>
        </Col>
        <Col span={6}>
          <Card 
            title="Properties Type Distribution" 
            bordered={false}
            style={{ borderRadius: '16px' }}
            headStyle={{ borderBottom: 'none', fontSize: '16px', fontWeight: 600 }}
          >
            <PropertiesTypeDistribution properties={properties} />
          </Card>
        </Col>
        <Col span={6}>
          <Card 
            title="Expenses Type Distribution" 
            bordered={false}
            style={{ borderRadius: '16px' }}
            headStyle={{ borderBottom: 'none', fontSize: '16px', fontWeight: 600 }}
          >
            <ExpensesTypeDistribution expenses={expenses} />
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};

export default Overview;
