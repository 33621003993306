import React, { useState } from 'react';
import { Input, message, Spin, Drawer, Button, List, Avatar, Tag } from 'antd';
import { UserOutlined, RobotOutlined, StarOutlined } from '@ant-design/icons';
import directus from '../utils/directusClient';
import { triggerFlow } from '@directus/sdk';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store'; // Import your RootState type
import axios, { AxiosResponse } from 'axios';

const flow_trigger_id = process.env.REACT_APP_ENV === 'prod'?
  "96b36378-a628-4f0c-921d-3d804c5d7b88": "a7ec107a-4164-472e-b18f-e2fe7a2af232"

const { TextArea } = Input;

const ChatBox: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [chatInputValue, setChatInputValue] = useState(''); // Input value for the chat drawer
  const [conversation, setConversation] = useState<{ type: string; content: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [chatVisible, setChatVisible] = useState(false); // Chat UI visibility

  // Redux selectors for business data
  const properties = useSelector((state: RootState) => state.properties.data);
  const leasings = useSelector((state: RootState) => state.leasings.data);
  const expenses = useSelector((state: RootState) => state.expenses.data);
  const tenants = useSelector((state: RootState) => state.tenants.data);
  
  

  // Handle sending message both for the TextField and inside the chat
  const handleSendMessage = async (messageContent: string) => {
    if (messageContent.trim() === '') {
      message.warning('Please enter a prompt.');
      return;
    }
  
    // Add the user's message to the conversation
    const userMessage = { type: 'user', content: messageContent };
    setConversation((prev) => [...prev, userMessage]);
  
    setLoading(true); // Start loading spinner
    setChatVisible(true); // Show chat UI
  
    // Format the conversation history to send it along with the new message
    const conversationHistory = conversation
      .map((msg) => `${msg.type === 'user' ? 'User' : 'Bot'}: ${msg.content}`)
      .join('\\n');
  
    const prompt = `
      context: You are a property management assistant and should only answer questions related to the data shared with you. Answer short and concise.\\n
      Conversation history: ${JSON.stringify(conversationHistory)}\\n
      User: ${messageContent}\\n
      leasings: ${JSON.stringify(leasings)}\\n
      properties: ${JSON.stringify(properties)}\\n
      tenants: ${JSON.stringify(tenants)}\\n
      expenses: ${JSON.stringify(expenses)}\\n
    `;
  
    try {
      const response = await directus.request(
        triggerFlow('POST', flow_trigger_id, { 
          "prompt": JSON.stringify(prompt)
        })
      ) as any;
  
      const serverResponse = response.choices[0].message.content || 'No valid response.';
  
      // Add the bot's response to the conversation
      const botMessage = { type: 'bot', content: serverResponse };
      setConversation((prev) => [...prev, botMessage]);
    } catch (error) {
      console.error('Error triggering flow:', error);
      message.error('Failed to get a response from the server.');
    } finally {
      setLoading(false); // End loading
    }
  };
  

  // Handle Enter press for both the TextArea and Chat Drawer
  const handleEnterPress = (event: React.KeyboardEvent<HTMLTextAreaElement>, isChatInput = false) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevent new line behavior
      const messageToSend = isChatInput ? chatInputValue : inputValue;
      handleSendMessage(messageToSend);

      // Clear the respective input field
      if (isChatInput) {
        setChatInputValue('');
      } else {
        setInputValue('');
      }
    }
  };

  // Close chat and clear conversation if necessary
  const closeChat = () => {
    setChatVisible(false);
    setConversation([]); // Optional: Clear conversation history when closing
  };

  return (
    <div style={{ textAlign: 'center'}}>
    {/* Original TextField that initializes the chat */}
    {!chatVisible && (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <TextArea
          placeholder="What would you like to know?"
          autoSize={{ minRows: 1, maxRows: 6 }}
          style={{
            width: '500px',
            borderRadius: '20px',
            border: 'none',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            padding: '10px 15px',
            resize: 'none',
            fontSize: '16px',
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => handleEnterPress(e)} // Send message on Enter
        />
        {/* Gold "Pro" star-like mark */}
        <Tag color="gold" style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
          <StarOutlined /> Pro
        </Tag>
      </div>
    )}

      {/* Chat Drawer that appears when chat is opened */}
      <Drawer
        title="AI Assistant"
        placement="right"
        closable={true}
        onClose={closeChat}
        visible={chatVisible}
        width={400}
        footer={
          <div>
            <TextArea
              placeholder="Type a message..."
              autoSize={{ minRows: 1, maxRows: 3 }}
              style={{ width: '100%', borderRadius: '5px' }}
              value={chatInputValue}
              onChange={(e) => setChatInputValue(e.target.value)}
              onKeyDown={(e) => handleEnterPress(e, true)} // Send message in chat on Enter
            />
          </div>
        }
        bodyStyle={{ backgroundColor: '#f5f5f5' }}
      >
        {loading && (
          <div style={{ textAlign: 'center', marginBottom: '10px' }}>
            <Spin />
          </div>
        )}

        <List
          dataSource={conversation}
          renderItem={(item) => (
            <List.Item style={{
              justifyContent: item.type === 'user' ? 'flex-end' : 'flex-start',
              padding: '8px 16px',
              border: 'none',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: item.type === 'user' ? 'row-reverse' : 'row',
                alignItems: 'flex-start',
                maxWidth: '80%',
                gap: '8px', // Consistent spacing between avatar and message
              }}>
                <Avatar 
                  size={32} // Fixed size for avatar
                  style={{ 
                    backgroundColor: item.type === 'bot' ? '#52c41a' : '#1890ff',
                    flexShrink: 0, // Prevent avatar from shrinking
                  }} 
                  icon={item.type === 'bot' ? <RobotOutlined /> : <UserOutlined />} 
                />
                <div style={{
                  backgroundColor: item.type === 'user' ? '#1890ff' : 'white',
                  padding: '12px 16px',
                  borderRadius: item.type === 'user' ? '20px 20px 0 20px' : '20px 20px 20px 0',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}>
                  <div style={{ 
                    color: item.type === 'user' ? 'white' : '#000000d9',
                    marginBottom: '4px',
                    fontWeight: 'bold'
                  }}>
                    {item.type === 'bot' ? 'Bot' : 'You'}
                  </div>
                  <div style={{ 
                    color: item.type === 'user' ? 'white' : '#000000d9',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}>
                    {item.content}
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </Drawer>
    </div>
  );
};

export default ChatBox;
