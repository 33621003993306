// AuthenticatedLayout.js
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import SideBar from './SideBar';
import AppHeader from './AppHeader';
import SearchBox from '../components/SearchBox'; // Import the new SearchBox component
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { fetchProperties } from '../redux/propertiesSlice';
import { fetchLeasings } from '../redux/leasingsSlice';
import { fetchTenants } from '../redux/tenantsSlice';
import { fetchExpenses } from '../redux/expensesSlice';
import { fetchPropertyFiles } from '../redux/filesSlice'


const { Content, Footer } = Layout;

const AuthenticatedLayout = ({ children, collapsed, handleCollapse }:any) => {
  const sidebarWidth = collapsed ? '50px' : '200px';
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
      dispatch(fetchProperties());
      dispatch(fetchLeasings());
      dispatch(fetchTenants());
      dispatch(fetchExpenses());
      dispatch(fetchPropertyFiles());
  }, [dispatch]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideBar collapsed={collapsed} handleCollapse={handleCollapse} />
      <Layout style={{ marginLeft: sidebarWidth, transition: 'margin-left 0.2s' }}>
        <AppHeader isSidebarCollapsed={collapsed} />
        <Content style={{ padding: '16px', marginTop: '50px' }}>
          {children}
        </Content>
        <Footer style={{
            backgroundColor: '#f5f5f5', // Light background color
            position: 'fixed',
            padding: '0 20px',
            height: "70px",
            bottom: 0,
            zIndex: 1,
            width: `calc(100% - ${sidebarWidth})`, // Full width minus sidebar
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        }}>
            <div > {/* Increase marginBottom for SearchBox */}
                <SearchBox /> {/* SearchBox at the top */}
            </div>
        </Footer>


      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
